// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarkItem_MarkItem__GA2Zk {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.MarkItem_MarkItem__GA2Zk:hover {
  opacity: 0.7;
}

.MarkItem_img__E8hZ6 {
  width: 40px;
  height: 40px;
}

.MarkItem_empty__OUaXh {
  width: 40px;
  height: 40px;
  background-color: #F6F6F6;
  color: black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/entity/Marks/ui/MarkItem/MarkItem.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACD;AAAC;EACC,YAAA;AAEF;;AAEA;EACC,WAAA;EACA,YAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD","sourcesContent":[".MarkItem {\r\n\twidth: 50px;\r\n\theight: 50px;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\ttransition: 0.3s;\r\n\t&:hover {\r\n\t\topacity: 0.7;\r\n\t}\r\n}\r\n\r\n.img {\r\n\twidth: 40px;\r\n\theight: 40px;\r\n}\r\n\r\n.empty {\r\n\twidth: 40px;\r\n\theight: 40px;\r\n\tbackground-color: #F6F6F6;\r\n\tcolor: black;\r\n\tborder-radius: 10px;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MarkItem": `MarkItem_MarkItem__GA2Zk`,
	"img": `MarkItem_img__E8hZ6`,
	"empty": `MarkItem_empty__OUaXh`
};
export default ___CSS_LOADER_EXPORT___;
