import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "app/providers/StoreProvider"
import { CarCardType } from "../types/cars"
import { AxiosError } from "axios"
import { ErrStatusCode } from "../types/cars"
import { $api } from "shared/api/api"

interface FindCarByIdProps {
	carId: number;
}

export const findCarById = createAsyncThunk<
	CarCardType, 
	FindCarByIdProps, 
	ThunkConfig<string>
>(
	"cars/findCarById",
	async (props, thunkAPI) => {
		const {
			rejectWithValue
		} = thunkAPI

		const {
			carId
		} = props

		try {
			const response = await $api.get<CarCardType>(`/cars/${carId}`, {
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			})

			return response.data
		} catch (e: unknown) {
			const err = e as AxiosError
			const status = err.response?.status as ErrStatusCode
			let message = ""
			switch (status) {
				case ErrStatusCode.NOT_FOUND: {
					message = "По вашему запросу ничего не найдено..."
					break
				}
				case ErrStatusCode.BAD_REQUEST: {
					message = "Произошла ошибка при попытке получения данных :("
					break
				}
				default: message = "Произошла ошибка. Попробуйте обновить страницу..."
			}
			return rejectWithValue(message)
		}	
	}
)