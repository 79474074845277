import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "app/providers/StoreProvider"
import { AxiosError } from "axios"
import { $api } from "shared/api/api"
import { ResponseForm } from "../../types/calculate"
import { validateForm } from "../validateForm/validateForm"
import { getCalculateFormForm } from "../../selectors/getCalculateFormForm/getCalculateFormForm"

export const calculateCarPrice = createAsyncThunk<
	ResponseForm, 
	void, 
	ThunkConfig<string | string[]>
>(
	"cars/calculateCarPrice",
	async (props, thunkAPI) => {
		const {
			rejectWithValue,
			getState
		} = thunkAPI

		const form = getCalculateFormForm(getState())

		const validateErrors = validateForm(form)

		if (validateErrors.length > 0) {
			return rejectWithValue(validateErrors)
		}

		const url = `/calculate?carPrice=${form?.price}&issueYear=${form?.issueYear}&fuelType=${form?.fuelType}&engineStats=${form?.engineStats}`

		console.log('rul', url)
		try {
			const response = await $api.get(url)

			return response.data
		} catch (e: unknown) {
			const err = e as AxiosError
			const status = err.response?.status
			if (status === 500) {
				return rejectWithValue("Внутренняя ошибка сервера...")
			}
			if (status === 400) {
				return rejectWithValue("Проверьте параметры ввода")
			}
		}	
	}
)