import { classNames } from "shared/lib/classNames/classNames";
import cls from "./PageError.module.scss"

interface PageErrorProps {
	className?: string;
}

export const PageError = ({ className }: PageErrorProps) => {

	const reloadPage = () => {
		window.location.reload()
	}

	return (
		<div className = {classNames(cls.PageError, {}, [className])}>
			<div className = {cls.content}>
				<p>Непредвиденная ошибка</p>
				<div className = {cls.contentBtn}>
					<button 
						onClick = {reloadPage}
					>
						Обновить страницу
					</button>
				</div>
			</div>
		</div>
	)
}