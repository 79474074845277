// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputRadio_wrap__Q37BB {
  display: flex;
  cursor: pointer;
  margin-bottom: 4px;
}

.InputRadio_radioText__Qj9uz {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/InputRadio/InputRadio.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,eAAA;EACA,kBAAA;AACD;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".wrap {\r\n\tdisplay: flex;\r\n\tcursor: pointer;\r\n\tmargin-bottom: 4px;\r\n}\r\n\r\n.radioText {\r\n\tmargin-left: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `InputRadio_wrap__Q37BB`,
	"radioText": `InputRadio_radioText__Qj9uz`
};
export default ___CSS_LOADER_EXPORT___;
