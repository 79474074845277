import { EntityState } from "@reduxjs/toolkit";
import { Marks } from "entity/Marks";

export enum ErrStatusCode {
	NOT_FOUND = 404,
	BAD_REQUEST = 400
}
export enum FuelTypeTranslation {
	GASOLINE = "gasoline",
	DIESEL = "diesel",
	ELECTRO = "electric",
	DIESEL_ELECTRIC = "diesel+electric",
  	GASOLINE_ELECTRIC = "gasoline+electric",
  	HYDROGEN = "hydrogen",
  	LPG = "lpg",
  	GASOLINE_LPG = "gasoline+lpg",
  	LPG_ELECTRIC = "lpg+electric",
}

export interface PhotoItemType {
	Id: number;
	CarId: number;
	Location: string;
}

export interface CarCardType {
	Id: number,
  	Manufacturer: Marks,
  	Model: string,
  	Badge: string,
  	BadgeDetail?: string,
  	EncarLink: string,
  	IssueYear: number,
	Price: number,
  	Mileage: number,
  	FuelType: string,
  	Engine: string,
  	Color?: string,
  	VIN: string,
  	PhotosPrefix: string,
	EngineStats: number,
	Transmission: string
}

export interface CarsSchema extends EntityState<CarCardType, number> {
	isLoading: boolean;
	error?: string;
	cars?: CarCardType[];
	favoritedCars?: CarCardType[];
	carDetail?: CarCardType;
	from: number;
	count: number;
	hasMore: boolean;
}

export interface FetchCarsQueryParams {
	from: number;
	count: number;
	manuf?: string;
	fuel?: string;
	price_min?: number,
	price_max?: number,
	year_min?: number;
	year_max?: number;
	mileage_min?: number;
	mileage_max?: number;
	color?: string;
	sortType?: string,
	engine_stats_min?: number,
	engine_stats_max?: number
}