import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "app/providers/StoreProvider"
import { CarCardType, FetchCarsQueryParams } from "../types/cars"
import { createDynamicUrl } from "./createDynamicUrl"
import { AxiosError } from "axios"
import { ErrStatusCode } from "../types/cars"
import { $api } from "shared/api/api"

export const fetchCarsPagination = createAsyncThunk<
	CarCardType[], 
	FetchCarsQueryParams, 
	ThunkConfig<string>
>(
	"cars/fetchCarsPagination",
	async (params, thunkAPI) => {
		const {
			rejectWithValue
		} = thunkAPI

		const url = createDynamicUrl(params)
		console.log('url', url)

		try {
			const response = await $api.get<CarCardType[]>(url, {
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			})

			return response.data
		} catch (e: unknown) {
			const err = e as AxiosError
			const status = err.response?.status as ErrStatusCode
			let message = ""
			switch (status) {
				case ErrStatusCode.NOT_FOUND: {
					message = "По вашему запросу ничего не найдено..."
					break
				}
				case ErrStatusCode.BAD_REQUEST: {
					message = "Произошла ошибка при попытке получения данных :("
					break
				}
				default: message = "Произошла ошибка. Попробуйте обновить страницу..."
			}
			return rejectWithValue(message)
		}	
	},
)