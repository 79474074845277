import { Text, TextSize, TextTheme, TextWeight } from "shared/ui/Text/Text"
import cls from "./CalculateResult.module.scss"
import { ResponseForm } from "../../model/types/calculate"
import { classNames } from "shared/lib/classNames/classNames"
import { memo } from "react"
import { numberWithSpaces } from "shared/lib/functions/numberWithSpaces"

interface CalculateResultProps {
	responseForm?: ResponseForm,
	totalPrice?: number,
	className?: string
}

export const CalculateResult = memo((props: CalculateResultProps) => {

	const {
		responseForm,
		totalPrice,
		className
	} = props

	return (
		<div className = {classNames(cls.totalPriceWrap, {}, [className])}>
			<div className = {cls.totalPriceContent}>
				<div className = {cls.total}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Итого:"}
						className = {cls.totalText}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(totalPrice || 0)}р`}
						className = {classNames(cls.totalPrice, {}, [cls.totalText])}
					/>
				</div>
				<div className = {cls.resale}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Цена автомобиля:"}
						theme = {TextTheme.GREY}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(responseForm?.CarPrice || 0)}р`}
						theme = {TextTheme.GREY}
						className = {cls.totalPrice}
					/>
				</div>
				<div className = {cls.resale}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Пошлина:"}
						theme = {TextTheme.GREY}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(responseForm?.CustomsDuty || 0)}р`}
						theme = {TextTheme.GREY}
						className = {cls.totalPrice}
					/>
				</div>
				<div className = {cls.resale}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Таможенный сбор:"}
						theme = {TextTheme.GREY}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(responseForm?.CustomsFee || 0)}р`}
						theme = {TextTheme.GREY}
						className = {cls.totalPrice}
					/>
				</div>
				<div className = {cls.resale}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Утилизационный сбор:"}
						theme = {TextTheme.GREY}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(responseForm?.RecyclingFee || 0)}р`}
						theme = {TextTheme.GREY}
						className = {cls.totalPrice}
					/>
				</div>
				<div className = {cls.resale}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Акциза:"}
						theme = {TextTheme.GREY}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(responseForm?.Excise || 0)}р`}
						theme = {TextTheme.GREY}
						className = {cls.totalPrice}
					/>
				</div>
				<div className = {cls.resale}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Услуги в Корее:"}
						theme = {TextTheme.GREY}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(responseForm?.ServicesInKorea || 0)}р`}
						theme = {TextTheme.GREY}
						className = {cls.totalPrice}
					/>
				</div>
				<div className = {cls.resale}>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {"Услуги в России:"}
						theme = {TextTheme.GREY}
					/>
					<Text
						weight = {TextWeight.XL}
						size = {TextSize.XS}
						text = {`${numberWithSpaces(responseForm?.ServicesInRF || 0)}р`}
						theme = {TextTheme.GREY}
						className = {cls.totalPrice}
					/>
				</div>
			</div>
		</div>
	)
})