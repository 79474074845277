// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppLink_AppLink__Ed-Ws {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
}

.AppLink_primary__pcosD {
  color: var(--link-text-color);
}

.AppLink_secondary__2B6K2 {
  color: var(--tg-theme-text-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/AppLink/AppLink.module.scss"],"names":[],"mappings":"AAAA;EACC,iCAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AACD;;AAEA;EACC,6BAAA;AACD;;AAEA;EACC,iCAAA;AACD","sourcesContent":[".AppLink {\r\n\tfont-family: \"Roboto\", sans-serif;\r\n\tfont-weight: 500;\r\n\tfont-style: normal;\r\n\ttext-decoration: none;\r\n}\r\n\r\n.primary {\r\n\tcolor: var(--link-text-color);\r\n}\r\n\r\n.secondary {\r\n\tcolor: var(--tg-theme-text-color)\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AppLink": `AppLink_AppLink__Ed-Ws`,
	"primary": `AppLink_primary__pcosD`,
	"secondary": `AppLink_secondary__2B6K2`
};
export default ___CSS_LOADER_EXPORT___;
