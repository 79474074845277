import { classNames, Mods } from "shared/lib/classNames/classNames"
import cls from "./FilterCarsForm.module.scss"
import LeftArrowIcon from "shared/assets/icons/LeftArrowIcon"
import { Text, TextSize, TextTheme, TextWeight } from "shared/ui/Text/Text"
import { memo, useCallback, useState } from "react"
import { Input } from "shared/ui/Input/Input"
import { MarkList } from "entity/Marks"
import { getFilterForm } from "../model/selectors/getFilterForm/getFIlterForm"
import { useSelector } from "react-redux"
import { filterActions } from "../model/slice/filterSlice"
import { useAppDispatch } from "shared/lib/hooks/useAppDispatch"
import { Button } from "shared/ui/Button/Button"
import { carsActions, fetchCars, FetchCarsQueryParams, getCarsFrom } from "entity/Cars"
import { ColorsList } from "entity/Colors"
import { colorsConfig } from "entity/Colors/model/config/colors"
import { ColorType } from "entity/Colors/model/types/colorsTypes"
import { SortInputRadio } from "entity/SelectSort"
import { checkNumberRegExp } from "shared/lib/regExp/checkNumber"
import { SortingType } from "entity/SelectSort"
import { Themes, useTheme } from "app/providers/ThemeProvider"

interface FilterCarsProps {
	className?: string;
	onCloseFilter?: () => void;
}

const engingeTypeValues = [
	{
		content: "Бензин",
		value: "gasoline"
	},
	{
		content: "Дизель",
		value: "diesel"
	},
	{
		content: "Гибрид",
		value: "gasoline"
	},
	{
		content: "Электро",
		value: "electric"
	},
]

const checkEngineSizeReg = new RegExp('^\d{1,3}(?:,\d{3})*(?:\.\d{1,9})?$');

export const FilterCarsForm = memo((props: FilterCarsProps) => {
	
	const { 
		className, 
		onCloseFilter
	} = props

	const dispatch = useAppDispatch()
	const [ isOpenMarks, setIsOpenMarks ] = useState<boolean>(false)
	const [ isOpenEngineType, setIsOpenEngineType ] = useState<boolean>(false)
	const [ isOpenColorForm, setIsOpenColorForm ] = useState<boolean>(false)
	const [ isEngineTypeContent, setIsEngineTypeContent ] = useState<string>("")
	const filterForm = useSelector(getFilterForm)
	const carsFrom = useSelector(getCarsFrom)
	const { theme } = useTheme()

	const onOpenMarks = useCallback(() => {
		setIsOpenMarks(true)
		window.scroll({top: 0, behavior: "smooth"})
	}, [])

	const onCloseMarks = useCallback(() => {
		setIsOpenMarks(false)
		window.scroll({top: 0, behavior: "smooth"})
	}, [])

	const onOpenEngineType = useCallback(() => {
		setIsOpenEngineType(true)
		window.scroll({top: 0, behavior: "smooth"})
	}, [])

	const onCloseEngineType = useCallback(() => {
		setIsOpenEngineType(false)
		window.scroll({top: 0, behavior: "smooth"})
	}, [])

	const onOpenColorForm = useCallback(() => {
		setIsOpenColorForm(true)
		window.scroll({top: 0, behavior: "smooth"})
	}, [])

	const onCloseColorForm = useCallback(() => {
		setIsOpenColorForm(false)
		window.scroll({top: 0, behavior: "smooth"})
	}, [])

	const onClearForm = useCallback(() => {
		dispatch(filterActions.clearForm())
		setIsEngineTypeContent("")
	}, [])

	const onChangeMark = useCallback((value: string) => {
		dispatch(filterActions.updateField({mark: value}))
		onCloseMarks()
	}, [])

	const onChangeYearMin = useCallback((value: number) => {
		//@ts-ignore
		if (checkNumberRegExp.test(value)) dispatch(filterActions.updateField({yearMin: Number(value || 0)}))
		if (!value) dispatch(filterActions.updateField({yearMin: 0}))
	}, [])

	const onChangeYearMax = useCallback((value: number) => {
		//@ts-ignore
		if (checkNumberRegExp.test(value)) dispatch(filterActions.updateField({yearMax: Number(value || 0)}))
		if (!value) dispatch(filterActions.updateField({yearMax: 0}))
	}, [])

	const onChangeMileageMin = useCallback((value: number) => {
		//@ts-ignore
		if (checkNumberRegExp.test(value)) dispatch(filterActions.updateField({mileageMin: Number(value || 0)}))
		if (!value) dispatch(filterActions.updateField({mileageMin: 0}))
	}, [])

	const onChangeMileageMax = useCallback((value: number) => {
		//@ts-ignore
		if (checkNumberRegExp.test(value)) dispatch(filterActions.updateField({mileageMax: Number(value || 0)}))
		if (!value) dispatch(filterActions.updateField({mileageMax: 0}))
	}, [])

	const onChangePriceMin = useCallback((value: number) => {
		//@ts-ignore
		if (checkNumberRegExp.test(value)) dispatch(filterActions.updateField({priceMin: Number(value || 0)}))
		if (!value) dispatch(filterActions.updateField({priceMin: 0}))
	}, [])

	const onChangePriceMax = useCallback((value: number) => {
		//@ts-ignore
		if (checkNumberRegExp.test(value)) dispatch(filterActions.updateField({priceMax: Number(value || 0)}))
		if (!value) dispatch(filterActions.updateField({priceMax: 0}))
	}, [])

	const onChangeEngineType = useCallback((content: string, value: string) => {
		//@ts-ignore
		dispatch(filterActions.updateField({engineType: value}))
		setIsEngineTypeContent(content)
		onCloseEngineType()
	}, [])
	
	const onChangeSizeMin = useCallback((value: any) => {
		//@ts-ignore
		if (!checkEngineSizeReg.test(value)) dispatch(filterActions.updateField({engineSizeMin: value}))
		if (!value) dispatch(filterActions.updateField({engineSizeMin: 0}))
	}, [])

	const onChangeSizeMax = useCallback((value: any) => {
		//@ts-ignore
		if (!checkEngineSizeReg.test(value)) dispatch(filterActions.updateField({engineSizeMax: value}))
		if (!value) dispatch(filterActions.updateField({engineSizeMax: 0}))
	}, [])

	const onChangeColor = useCallback((value: ColorType) => {
		dispatch(filterActions.updateField({color: value}))
		onCloseColorForm()
	}, [])

	const onChangeSortType = useCallback((value: SortingType) => {
		dispatch(filterActions.updateField({sortType: value}))
	}, [])

	const onFilterCars = async () => {
		window.scroll({top: 0, behavior: "smooth"})
		dispatch(carsActions.setCarsFrom(0))
		const queryParams: FetchCarsQueryParams = {
			manuf: filterForm?.mark,
			fuel: filterForm?.engineType,
			mileage_max: filterForm?.mileageMax,
			mileage_min: filterForm?.mileageMin,
			year_max: filterForm?.yearMax,
			year_min: filterForm?.yearMin,
			from: 0,
			count: 80,
			color: filterForm?.color as string,
			sortType: filterForm?.sortType as string
		}
		dispatch(filterActions.setCopyForm())
		//@ts-ignore
		const res = await dispatch(fetchCars(queryParams))
		onCloseFilter?.()
	}

	const isOpenForm = isOpenMarks || isOpenEngineType || isOpenColorForm

	const engineTypeTextMods: Mods = {
		[cls.darkEngineTypeText]: theme === Themes.DARK ? true : false
	}

	return (
		<div className = {classNames(cls.FilterCarsForm, {}, [className])}>
			{
				isOpenMarks &&
				<>
					<div className = {cls.iconWrap} onClick = {onCloseMarks}>
						<LeftArrowIcon/>
					</div>
					<div className = {cls.textWrap}>
						<Text
							text = {"Выберите марку"}
							weight = {TextWeight.XL}
							size = {TextSize.L}
						/>
					</div>
					<MarkList
						className = {cls.markList}
						forFilter = {true}
						onSetMark = {onChangeMark}
					/>
				</>
			}
			{
				isOpenEngineType &&
				<>
					<div className = {cls.headerEngineType}>
						<div className = {cls.iconEngineWrap} onClick = {onCloseEngineType}>
							<LeftArrowIcon/>
						</div>
						<Text
							text = {"Выберите тип двигателя"}
							weight = {TextWeight.XL}
						/>
					</div>
					<div className = {cls.engineTypeWrap}>
						{engingeTypeValues.map((item) => {
							return (
								<div className = {cls.engineType} onClick = {() => onChangeEngineType(item.content, item.value)}>
									<Text
										className = {classNames(cls.engineTypeText, engineTypeTextMods)}
										text = {item.content}
										weight = {TextWeight.S}
									/>
								</div>
							)
						})}
					</div>
				</>
			}
			{
				isOpenColorForm &&
				<>
					<div className = {cls.headerEngineType}>
						<div className = {cls.iconEngineWrap} onClick = {onCloseColorForm}>
							<LeftArrowIcon/>
						</div>
						<Text
							text = {"Выберите цвет"}
							weight = {TextWeight.XL}
						/>
					</div>
					<ColorsList
						className = {cls.colorsList}
						onChangeColor = {onChangeColor}
					/>
				</>
			}
			<div style = {isOpenForm ? {display: "none", pointerEvents: "none"} : {}}>
				<div className = {cls.header}>
					<span onClick = {onCloseFilter} className = {cls.icon}>
						<LeftArrowIcon/>
					</span>
					<Text
						text = {"Фильтры"}
						weight = {TextWeight.XL}
						size = {TextSize.L}
					/>
					<span onClick = {onClearForm}>
						<Text
							text = {"Сбросить"}
							weight = {TextWeight.XL}
							theme = {TextTheme.LINK}
							className = {cls.removeFilter}
						/>
					</span>
				</div>
				<div className = {cls.content}>
					<div className = {cls.markWrap}>
						<Text
							text = {"Марка"}
							weight = {TextWeight.XL}
						/>
						<span onClick = {onOpenMarks}>
							<Input
								className = {cls.inputMark}
								placeholder = {"Любая"}
								value = {filterForm?.mark || ""}
								readonly
							/>
						</span>
					</div>
					<div className = {cls.yearWrap}>
						<Text
							text = {"Год выпуска"}
							weight = {TextWeight.XL}
						/>
						<div className = {cls.yearWraps}>
							<Input
								className = {cls.inputYear}
								placeholder = {"От"}
								value = {filterForm?.yearMin || ""}
								onChange = {onChangeYearMin}
							/>
							<Input
								className = {cls.inputYear}
								placeholder = {"До"}
								value = {filterForm?.yearMax || ""}
								onChange = {onChangeYearMax}
							/>
						</div>
					</div>
					<div className = {cls.yearWrap}>
						<Text
							text = {"Пробег"}
							weight = {TextWeight.XL}
						/>
						<div className = {cls.yearWraps}>
							<Input
								className = {cls.inputYear}
								placeholder = {"От"}
								value = {filterForm?.mileageMin || ""}
								onChange = {onChangeMileageMin}
							/>
							<Input
								className = {cls.inputYear}
								placeholder = {"До"}
								value = {filterForm?.mileageMax || ""}
								onChange = {onChangeMileageMax}
							/>
						</div>
					</div>
					<div className = {cls.yearWrap}>
						<Text
							text = {"Цена"}
							weight = {TextWeight.XL}
						/>
						<div className = {cls.yearWraps}>
							<Input
								className = {cls.inputYear}
								placeholder = {"От"}
								value = {filterForm?.priceMin || ""}
								onChange = {onChangePriceMin}
							/>
							<Input
								className = {cls.inputYear}
								placeholder = {"До"}
								value = {filterForm?.priceMax || ""}
								onChange = {onChangePriceMax}
							/>
						</div>
					</div>
					<Text
						text = {"Тип двигателя"}
						weight = {TextWeight.XL}
						className = {cls.engine}
					/>
					<span onClick = {onOpenEngineType}>
						<Input
							className = {cls.inputMark}
							placeholder = {"Любой"}
							value = {isEngineTypeContent || ""}
							readonly
						/>
					</span>
					<div className = {cls.yearWrap}>
						<Text
							text = {filterForm?.engineType === "electric" ? "Мощность двигателя кВт" : "Объём двигателя см3"}
							weight = {TextWeight.XL}
						/>
						<div className = {cls.yearWraps}>
							<Input
								className = {cls.inputYear}
								placeholder = {"От"}
								value = {filterForm?.engineSizeMin || ""}
								onChange = {onChangeSizeMin}
							/>
							<Input
								className = {cls.inputYear}
								placeholder = {"До"}
								value = {filterForm?.engineSizeMax || ""}
								onChange = {onChangeSizeMax}
							/>
						</div>
					</div>
					<div className = {cls.colorWrap}>
						<Text
							text = {"Цвет"}
							weight = {TextWeight.XL}
						/>
						<span onClick = {onOpenColorForm}>
							<Input
								className = {cls.inputMark}
								placeholder = {"Любой"}
								value = {filterForm?.color && colorsConfig[filterForm.color].content}
								readonly
							/>
						</span>
					</div>
					<div className = {cls.sortWrap}>
						<Text
							text = {"Сортировка"}
							weight = {TextWeight.XL}
						/>
						<SortInputRadio
							className = {cls.sortList}
							onChangeSortType = {onChangeSortType}
						/>
					</div>
				</div>
				<div className = {cls.sendButton}>
					<Button onClick = {onFilterCars}>
						Показать результаты
					</Button>
				</div>
			</div>
		</div>
	)
})