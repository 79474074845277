// Light icon
import BenzLight from "shared/assets/images/mark-light/mark-merc.png"
import AudiLight from "shared/assets/images/mark-light/mark-audi.png"
import RollsLight from "shared/assets/images/mark-light/mark-rolls.png"
import BmwLight from "shared/assets/images/mark-light/mark-bmw.png"
import TeslaLight from "shared/assets/images/mark-light/mark-tesla.png"
import PorcheLight from "shared/assets/images/mark-light/mark-porche.png"
import CadillacLight from "shared/assets/images/mark-light/mark-cadilac.png"
import ChanganLight from "shared/assets/images/mark-light/mark-changan.png"
import FerrariLight from "shared/assets/images/mark-light/mark-ferrari.png"
import FordLight from "shared/assets/images/mark-light/mark-ford.png"
import GenesisLight from "shared/assets/images/mark-light/mark-genesis.png"
import HyndaiLight from "shared/assets/images/mark-light/mark-hyndai.png"
import KiaLight from "shared/assets/images/mark-light/mark-kia.png"
import LamboLight from "shared/assets/images/mark-light/mark-lambo.png"
import McLarenLight from "shared/assets/images/mark-light/mark-mclaren.png"
import NissanLight from "shared/assets/images/mark-light/mark-nissan.png"
import ToyotaLight from "shared/assets/images/mark-light/mark-toyota.png"
import BentleyLight from "shared/assets/images/mark-light/mark-bentley.png"
import CherryLight from "shared/assets/images/mark-light/mark-cherry.png"
import ChevroletLight from "shared/assets/images/mark-light/mark-chevrolet.png"
import ChryslerLight from "shared/assets/images/mark-light/mark-chrysler.png"
import CitroenLight from "shared/assets/images/mark-light/mark-citroen.png"
import CooperLight from "shared/assets/images/mark-light/mark-cooper.png"
import GeelyLight from "shared/assets/images/mark-light/mark-geely.png"
import HondaLight from "shared/assets/images/mark-light/mark-honda.png"
import JaguarLight from "shared/assets/images/mark-light/mark-jaguar.png"
import JeepLight from "shared/assets/images/mark-light/mark-jeep.png"
import LandLight from "shared/assets/images/mark-light/mark-land.png"
import LexusLight from "shared/assets/images/mark-light/mark-lexus.png"
import LincolnLight from "shared/assets/images/mark-light/mark-lincoln.png"
import MaseratiLight from "shared/assets/images/mark-light/mark-maserati.png"
import MisubishiLight from "shared/assets/images/mark-light/mark-misubishi.png"
import PeugeotLight from "shared/assets/images/mark-light/mark-peugeot.png"
import RenaultLight from "shared/assets/images/mark-light/mark-renault.png"
import SsangLight from "shared/assets/images/mark-light/mark-ssang.png"
import VolvoLight from "shared/assets/images/mark-light/mark-volvo.png"

// Dark icon
import AudiDark from "shared/assets/images/mark-dark/mark-audi.png"
import BentleyDark from "shared/assets/images/mark-dark/mark-bentley.png"
import BmwDark from "shared/assets/images/mark-dark/mark-bmw.png"
import CadillacDark from "shared/assets/images/mark-dark/mark-cadilac.png"
import ChanganDark from "shared/assets/images/mark-dark/mark-changan.png"
import CherryDark from "shared/assets/images/mark-dark/mark-cherry.png"
import ChevroletDark from "shared/assets/images/mark-dark/mark-chevrolet.png"
import ChryslerDark from "shared/assets/images/mark-dark/mark-chrysler.png"
import CitroenDark from "shared/assets/images/mark-dark/mark-citroen.png"
import CooperDark from "shared/assets/images/mark-dark/mark-cooper.png"
import FerrariDark from "shared/assets/images/mark-dark/mark-ferrari.png"
import FordDark from "shared/assets/images/mark-dark/mark-ford.png"
import GeelyDark from "shared/assets/images/mark-dark/mark-geely.png"
import GenesisDark from "shared/assets/images/mark-dark/mark-genesis.png"
import HondaDark from "shared/assets/images/mark-dark/mark-honda.png"
import HyndaiDark from "shared/assets/images/mark-dark/mark-hyndai.png"
import JaguarDark from "shared/assets/images/mark-dark/mark-jaguar.png"
import JeepDark from "shared/assets/images/mark-dark/mark-jeep.png"
import KiaDark from "shared/assets/images/mark-dark/mark-kia.png"
import LamboDark from "shared/assets/images/mark-dark/mark-lambo.png"
import LandDark from "shared/assets/images/mark-dark/mark-land.png"
import LexusDark from "shared/assets/images/mark-dark/mark-lexus.png"
import LincolnDark from "shared/assets/images/mark-dark/mark-lincoln.png"
import MaseratiDark from "shared/assets/images/mark-dark/mark-maserati.png"
import McLarenDark from "shared/assets/images/mark-dark/mark-mclaren.png"
import MercDark from "shared/assets/images/mark-dark/mark-merc.png"
import MisubishiDark from "shared/assets/images/mark-dark/mark-misubishi.png"
import NissanDark from "shared/assets/images/mark-dark/mark-nissan.png"
import PeugeotDark from "shared/assets/images/mark-dark/mark-peugeot.png"
import PorcheDark from "shared/assets/images/mark-dark/mark-porche.png"
import RenaultDark from "shared/assets/images/mark-dark/mark-renault.png"
import RollsDark from "shared/assets/images/mark-dark/mark-rolls.png"
import SsangDark from "shared/assets/images/mark-dark/mark-ssang.png"
import TeslaDark from "shared/assets/images/mark-dark/mark-tesla.png"
import ToyotaDark from "shared/assets/images/mark-dark/mark-toyota.png"
import VolvoDark from "shared/assets/images/mark-dark/mark-volvo.png"

import { MarkItem, Marks } from "./types/markTypes"

export const marks: Record<Marks, MarkItem> = {
	[Marks.BENZ]: {
		imageLight: BenzLight,
		imageDark: MercDark,
		title: Marks.BENZ,
		link: "benz"
	},
	[Marks.AUDI]: {
		imageLight: AudiLight,
		imageDark: AudiDark,
		title: Marks.AUDI,
		link: "audi"
	},
	[Marks.BMW]: {
		imageLight: BmwLight,
		imageDark: BmwDark,
		title: Marks.BMW,
		link: "bmw"
	},
	[Marks.HYUNDAI]: {
		imageLight: HyndaiLight,
		imageDark: HyndaiDark,
		title: Marks.HYUNDAI,
		link: "hyundai"
	},
	[Marks.TOYOTA]: {
		imageLight: ToyotaLight,
		imageDark: ToyotaDark,
		title: Marks.TOYOTA,
		link: "toyota"
	},
	[Marks.KIA]: {
		imageLight: KiaLight,
		imageDark: KiaDark,
		title: Marks.KIA,
		link: "kia"
	},
	[Marks.ROLLS_ROYCE]: {
		imageLight: RollsLight,
		imageDark: RollsDark,
		title: Marks.ROLLS_ROYCE,
		link: "rolls_royce"
	},
	[Marks.TESLA]: {
		imageLight: TeslaLight,
		imageDark: TeslaDark,
		title: Marks.TESLA,
		link: "tesla"
	},
	[Marks.PORSCHE]: {
		imageLight: PorcheLight,
		imageDark: PorcheDark,
		title: Marks.PORSCHE,
		link: "porsche"
	},
	[Marks.LINCOLN]: {
		imageLight: LincolnLight,
		imageDark: LincolnDark,
		title: Marks.LINCOLN,
		link: "lincoln"
	},
	[Marks.HONDA]: {
		imageLight: HondaLight,
		imageDark: HondaDark,
		title: Marks.HONDA,
		link: "honda"
	},
	[Marks.FORD]: {
		imageLight: FordLight,
		imageDark: FordDark,
		title: Marks.FORD,
		link: "ford"
	},
	[Marks.MASERATI]: {
		imageLight: MaseratiLight,
		imageDark: MaseratiDark,
		title: Marks.MASERATI,
		link: "maserati"
	},
	[Marks.CHERRY]: {
		imageLight: CherryLight,
		imageDark: CherryDark,
		title: Marks.CHERRY,
		link: "cherry"
	},
	[Marks.CITROEN]: {
		imageLight: CitroenLight,
		imageDark: CitroenDark,
		title: Marks.CITROEN,
		link: "citroen"
	},
	[Marks.BENTLEY]: {
		imageLight: BentleyLight,
		imageDark: BentleyDark,
		title: Marks.BENTLEY,
		link: "bentley"
	},
	[Marks.PEUGEOT]: {
		imageLight: PeugeotLight,
		imageDark: PeugeotDark,
		title: Marks.PEUGEOT,
		link: "peugeot"
	},
	[Marks.JAGUAR]: {
		imageLight: JaguarLight,
		imageDark: JaguarDark,
		title: Marks.JAGUAR,
		link: "jaguar"
	},
	[Marks.LEXUS]: {
		imageLight: LexusLight,
		imageDark: LexusDark,
		title: Marks.LEXUS,
		link: "lexus"
	},
	[Marks.MINI]: {
		imageLight: CooperLight,
		imageDark: CooperDark,
		title: Marks.MINI,
		link: "mini"
	},
	[Marks.CHEVROLET]: {
		imageLight: ChevroletLight,
		imageDark: ChevroletDark,
		title: Marks.CHEVROLET,
		link: "chevrolet"
	},
	[Marks.LAND_ROVER]: {
		imageLight: LandLight,
		imageDark: LandDark,
		title: Marks.LAND_ROVER,
		link: "land_rover"
	},
	[Marks.JEEP]: {
		imageLight: JeepLight,
		imageDark: JeepDark,
		title: Marks.JEEP,
		link: "jeep"
	},
	[Marks.VOLVO]: {
		imageLight: VolvoLight,
		imageDark: VolvoDark,
		title: Marks.VOLVO,
		link: "volvo"
	},
	[Marks.CADILLAC]: {
		imageLight: CadillacLight,
		imageDark: CadillacDark,
		title: Marks.CADILLAC,
		link: "cadillac"
	},
	[Marks.NISSAN]: {
		imageLight: NissanLight,
		imageDark: NissanDark,
		title: Marks.NISSAN,
		link: "nissan"
	},
	[Marks.RENAULT_KOREA]: {
		imageLight: RenaultLight,
		imageDark: RenaultDark,
		title: Marks.RENAULT_KOREA,
		link: "renault_korea"
	},
	[Marks.GENESIS]: {
		imageLight: GenesisLight,
		imageDark: GenesisDark,
		title: Marks.GENESIS,
		link: "genesis"
	},
	[Marks.MITSUBISHI]: {
		imageLight: MisubishiLight,
		imageDark: MisubishiDark,
		title: Marks.MITSUBISHI,
		link: "mitsubishi"
	},
	[Marks.KG_MOBILITY]: {
		imageLight: SsangLight,
		imageDark: SsangDark,
		title: Marks.KG_MOBILITY,
		link: "kg_mobility"
	},
	[Marks.CHRYSLER]: {
		imageLight: ChryslerLight,
		imageDark: ChryslerDark,
		title: Marks.CHRYSLER,
		link: "chrysler"
	},
	[Marks.FERRARI]: {
		imageLight: FerrariLight,
		imageDark: FerrariDark,
		title: Marks.FERRARI,
		link: "ferrari"
	},
	[Marks.LAMBORGHINI]: {
		imageLight: LamboLight,
		imageDark: LamboDark,
		title: Marks.LAMBORGHINI,
		link: "lamborghini"
	},
	[Marks.GEELY]: {
		imageLight: GeelyLight,
		imageDark: GeelyDark,
		title: Marks.GEELY,
		link: "geely"
	},
	[Marks.CHANGAN]: {
		imageLight: ChanganLight,
		imageDark: ChanganDark,
		title: Marks.CHANGAN,
		link: "changan"
	},
	[Marks.MC_LAREN]: {
		imageLight: McLarenLight,
		imageDark: McLarenDark,
		title: Marks.MC_LAREN,
		link: "mc_laren"
	}
}