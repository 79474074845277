export enum Marks {
  GEELY = "Geely",
  CHANGAN = "Changan",
  MC_LAREN = "McLaren",
  LINCOLN = "Lincoln",
  HONDA = "Honda",
  FORD = "Ford",
  MASERATI = "Maserati",
  CHERRY = "Cherry",
  CITROEN = "Citroen",
  BENTLEY = "Bentley",
  BENZ = "Mercedes Benz",
  PEUGEOT = "Peugeot",
  PORSCHE = "Porsche",
  AUDI = "Audi",
  ROLLS_ROYCE = "Rolls-Royce",
  TESLA = "Tesla",
  JAGUAR = "Jaguar",
  LEXUS = "Lexus",
  MINI = "Mini",
  LAMBORGHINI = "Lamborghini",
  CHEVROLET = "Chevrolet",
  LAND_ROVER = "Land Rover",
  JEEP = "Jeep",
  VOLVO = "Volvo",
  CADILLAC = "Cadillac",
  NISSAN = "Nissan",
  RENAULT_KOREA = "Renault Korea",
  KIA = "KIA",
  GENESIS = "Genesis",
  HYUNDAI = "Hyundai",
  MITSUBISHI = "Mitsubishi",
  BMW = "BMW",
  KG_MOBILITY = "KG Mobility",
  CHRYSLER = "Chrysler",
  FERRARI = "Ferrari",
  TOYOTA = "Toyota",
}

export interface MarkItem {
	imageLight: string;
  imageDark: string;
	title: Marks;
  link: string;
}