import { memo, CSSProperties } from "react"
import { classNames } from "shared/lib/classNames/classNames"
import cls from "./Text.module.scss"
import { useTelegram } from "shared/lib/hooks/useTelegram";

export enum TextTheme {
	PRIMARY = "primary",
	SECONDARY = "secondary",
	GREY = "grey",
	LINK = "linkColor"
}

export enum TextSize {
	XXS = "size_xxs",
	XS = "size_xs",
	S = "size_s",
	M = "size_m",
	L = "size_l",
	XL = "size_xl",
	XXL = "size_xxl"
}

export enum TextWeight {
	XS = "weight_xs",
	S = "weight_s",
	M = "weight_m",
	L = "weight_l",
	XL = "weight_xl",
	XXL = "weight_xxl"
}

export enum TextAlign {
	LEFT = "align_left",
	RIGHT = "align_right",
	CENTER = "align_center",
}

interface TextProps {
	className?: string;
	text: string;
	align?: TextAlign;
	theme?: TextTheme;
	weight?: TextWeight,
	size?: TextSize;
	link?: boolean;
	style?: CSSProperties
}

export const Text = memo((props: TextProps) => {

	const {
		className,
		text,
		align = TextAlign.LEFT,
		theme = TextTheme.PRIMARY,
		weight = TextWeight.M,
		size = TextSize.M,
		link,
		style = {}
	} = props

	const mods = {
		[cls[theme]]: true,
		[cls[size]]: true,
		[cls[align]]: true,
		[cls[weight]]: true,
		[cls.link]: link
	}
	
	return (
		<div className = {classNames(cls.Text, mods, [className])}>
			{text && <p style = {style} className = {cls.text}>{text}</p>}
		</div>
	)
})