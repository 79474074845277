import { marks } from "../../model/marks"
import { classNames } from "shared/lib/classNames/classNames"
import cls from "./MarkList.module.scss"
import { MarkItem } from "../MarkItem/MarkItem"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text"
import { AppLink, AppLinkTheme } from "shared/ui/AppLink/AppLink"
import { routerPath } from "shared/config/routeConfig/routeConfig"
import { memo } from "react"

interface MarkListProps {
	className?: string;
	forFilter: boolean;
	onSetMark?: (value: string) => void;
}

export const MarkList = memo((props: MarkListProps) => {

	const {
		className,
		forFilter,
		onSetMark
	} = props

	return (
		<div className = {classNames(cls.MarkList, {}, [className])}>
			{Object.values(marks).map((item) => {
				return (
					<div 
						className = {classNames(cls.markWrap, {[cls.cursor]: forFilter}, [])} 
						onClick = {() => onSetMark?.(item.title.toLowerCase())}
					>
						{
							!forFilter ?
							<AppLink 
								to = {`${routerPath.marks_detail}${item.link}`} 
								theme = {AppLinkTheme.SECONDARY}
								className = {cls.link}
							>
								<MarkItem
									item = {item}
								/>
								<Text
									className = {cls.text}
									text = {item.title}
									weight = {TextWeight.S}
									size = {TextSize.XXS}
								/>
							</AppLink>
							: 
							<>
								<MarkItem
									item = {item}
								/>
								<Text
									className = {cls.text}
									text = {item.title}
									weight = {TextWeight.S}
									size = {TextSize.XXS}
								/>
							</>		
						}
					</div>	
				)
			})}
		</div>
	)
})