// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorsListItem_ColorsListItem__D89X2 {
  margin-top: 30px;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.ColorsListItem_color__CmGO5 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ColorsListItem_text__2e\\+-x {
  margin-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/entity/Colors/ui/ColorsListItem/ColorsListItem.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;AACD;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".ColorsListItem {\r\n\tmargin-top: 30px;\r\n\tdisplay: flex;\r\n\twidth: 100%;\r\n\talign-items: center;\r\n\tcursor: pointer;\r\n}\r\n\r\n.color {\r\n\twidth: 40px;\r\n\theight: 40px;\r\n\tborder-radius: 50%;\r\n}\r\n\r\n.text {\r\n\tmargin-left: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ColorsListItem": `ColorsListItem_ColorsListItem__D89X2`,
	"color": `ColorsListItem_color__CmGO5`,
	"text": `ColorsListItem_text__2e+-x`
};
export default ___CSS_LOADER_EXPORT___;
