import { CalculateForm } from "../../types/calculate"

export function validateForm(form?: CalculateForm) {
	
	if (!form) {
		return ["No data"]
	}
	
	const { engineStats, fuelType, issueYear, price } = form
	const errors = []

	if (!engineStats || !fuelType || !issueYear || !price) {
		errors.push("Все поля формы должны быть заполнены")
	}

	if (!engineStats || !Number.isInteger(engineStats) || !(engineStats >= 0)) {
		errors.push("Некорректная мощность двигателя")
	}

	if (!issueYear || !Number.isInteger(issueYear) || !(issueYear >= 0)) {
		errors.push("Некорректный год выпуска автомобиля")
	}

	if (!price || !Number.isInteger(price) || !(price >= 0)) {
		errors.push("Некорректная цена автомобиля")
	}

	return errors
}