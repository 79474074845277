export interface CarPhoto {
	url: string,
	id: string
}

export function createCarPhotosArray(baseUrl: string, photosCount: number = 15): CarPhoto[] {
	const array: CarPhoto[] = []
	const singleDigitStr = "00"
	const doubleDigitStr = "0"
	const photoFormat = ".jpg"

	for (let i = 1; i <= photosCount; i++) {
		if (i < 10) {
			const carUrl = baseUrl + `${singleDigitStr}${i}` + photoFormat
			array.push({ url: carUrl, id: `photo${i}` })
		} else if (i < 99) {
			const carUrl = baseUrl + `${doubleDigitStr}${i}` + photoFormat
			array.push({ url: carUrl, id: `photo${i}` })
		} else {
			const carUrl = baseUrl + `${i}` + photoFormat
			array.push({ url: carUrl, id: `photo${i}` })
		}
	}

	return array
}