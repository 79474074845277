// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalculateResult_totalPriceWrap__ljKxl {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.CalculateResult_totalPriceContent__rs7bz {
  width: 350px;
  padding: 40px 25px;
  background-color: #F6F6F6;
  border-radius: 10px;
}

.CalculateResult_total__ReYzw {
  display: flex;
  align-items: center;
}

.CalculateResult_totalText__1xcts {
  color: black;
}

.CalculateResult_totalPrice__64Hka {
  margin-left: 45px;
}

.CalculateResult_resale__7PQ4j {
  display: flex;
  align-items: center;
  margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/feautures/CalculateCar/ui/CalculateResult/CalculateResult.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AACD;;AAEA;EACC,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;AACD;;AAEA;EACC,aAAA;EACA,mBAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EACC,iBAAA;AACD;;AAEA;EACC,aAAA;EACA,mBAAA;EACA,gBAAA;AACD","sourcesContent":[".totalPriceWrap {\r\n\twidth: 100%;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\tmargin-top: 20px;\r\n}\r\n\r\n.totalPriceContent {\r\n\twidth: 350px;\r\n\tpadding: 40px 25px;\r\n\tbackground-color: #F6F6F6;\r\n\tborder-radius: 10px;\r\n}\r\n\r\n.total {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n}\r\n\r\n.totalText {\r\n\tcolor: black;\r\n}\r\n\r\n.totalPrice {\r\n\tmargin-left: 45px;\r\n}\r\n\r\n.resale {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tmargin-top: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalPriceWrap": `CalculateResult_totalPriceWrap__ljKxl`,
	"totalPriceContent": `CalculateResult_totalPriceContent__rs7bz`,
	"total": `CalculateResult_total__ReYzw`,
	"totalText": `CalculateResult_totalText__1xcts`,
	"totalPrice": `CalculateResult_totalPrice__64Hka`,
	"resale": `CalculateResult_resale__7PQ4j`
};
export default ___CSS_LOADER_EXPORT___;
