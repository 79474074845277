// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_Button__KZLLT {
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: var(--font-weight-XL);
  font-size: var(--font-size-M);
  font-style: normal;
  transition: 0.3s;
}
.Button_Button__KZLLT:hover {
  opacity: 0.8;
}

.Button_clear__fAaR2 {
  color: var(--secondary-color);
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

.Button_background__E9tlv {
  background: var(--button-background-primary);
  color: white;
  border: none;
}

.Button_size_s__oVZM1 {
  font-size: var(--font-size-S);
  height: var(--button-s-height);
}

.Button_size_m__lYZg2 {
  font-size: var(--font-size-M);
  height: var(--button-m-height);
}

.Button_size_l__5o-0J {
  font-size: var(--font-size-L);
  height: var(--button-l-height);
}

.Button_size_xl__ML30g {
  font-size: var(--font-size-XL);
  height: var(--button-xl-height);
}

.Button_disabled__8gh5t {
  opacity: 0.5;
}

.Button_hovered__W6cai:hover {
  opacity: 0.7;
  transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iCAAA;EACA,kCAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;AACD;AAAC;EACC,YAAA;AAEF;;AAEA;EACC,6BAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AACD;;AAEA;EACC,4CAAA;EACA,YAAA;EACA,YAAA;AACD;;AAEA;EACC,6BAAA;EACA,8BAAA;AACD;;AAEA;EACC,6BAAA;EACA,8BAAA;AACD;;AAEA;EACC,6BAAA;EACA,8BAAA;AACD;;AAEA;EACC,8BAAA;EACA,+BAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EACC,YAAA;EACA,gBAAA;AACD","sourcesContent":[".Button {\r\n\tcursor: pointer;\r\n\tcolor: white;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tpadding: 8px 20px;\r\n\tborder-radius: 10px;\r\n\tfont-family: \"Roboto\", sans-serif;\r\n\tfont-weight: var(--font-weight-XL);\r\n\tfont-size: var(--font-size-M);\r\n\tfont-style: normal;\r\n\ttransition: 0.3s;\r\n\t&:hover {\r\n\t\topacity: 0.8;\r\n\t}\r\n}\r\n\r\n.clear {\r\n\tcolor: var(--secondary-color);\r\n\tpadding: 0;\r\n\tbackground: none;\r\n\tborder: none;\r\n\toutline: none;\r\n}\r\n\r\n.background {\r\n\tbackground: var(--button-background-primary);\r\n\tcolor: white;\r\n\tborder: none;\r\n}\r\n\r\n.size_s {\r\n\tfont-size: var(--font-size-S);\r\n\theight: var(--button-s-height);\r\n}\r\n\r\n.size_m {\r\n\tfont-size: var(--font-size-M);\r\n\theight: var(--button-m-height);\r\n}\r\n\r\n.size_l {\r\n\tfont-size: var(--font-size-L);\r\n\theight: var(--button-l-height);\r\n}\r\n\r\n.size_xl {\r\n\tfont-size: var(--font-size-XL);\r\n\theight: var(--button-xl-height);\r\n}\r\n\r\n.disabled {\r\n\topacity: 0.5;\r\n}\r\n\r\n.hovered:hover {\r\n\topacity: 0.7;\r\n\ttransition: 0.3s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `Button_Button__KZLLT`,
	"clear": `Button_clear__fAaR2`,
	"background": `Button_background__E9tlv`,
	"size_s": `Button_size_s__oVZM1`,
	"size_m": `Button_size_m__lYZg2`,
	"size_l": `Button_size_l__5o-0J`,
	"size_xl": `Button_size_xl__ML30g`,
	"disabled": `Button_disabled__8gh5t`,
	"hovered": `Button_hovered__W6cai`
};
export default ___CSS_LOADER_EXPORT___;
