// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CarsItem_CarsItem__wmqD5 {
  margin-bottom: 15px;
}

.CarsItem_imgWrap__j\\+pU9 {
  position: relative;
}

.CarsItem_img__ierON {
  width: 160px;
  height: 150px;
  border-radius: 24px;
  object-fit: cover;
}

.CarsItem_iconWrap__ZPOSq {
  position: absolute;
  top: 12px;
  left: 12px;
}

.CarsItem_textWrap__arwEC {
  width: 160px;
}`, "",{"version":3,"sources":["webpack://./src/entity/Cars/ui/CarsItem/CarsItem.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AACD;;AAEA;EACC,kBAAA;EACA,SAAA;EACA,UAAA;AACD;;AAEA;EACC,YAAA;AACD","sourcesContent":[".CarsItem {\r\n\tmargin-bottom: 15px;\r\n}\r\n\r\n.imgWrap {\r\n\tposition: relative;\r\n}\r\n\r\n.img {\r\n\twidth: 160px;\r\n\theight: 150px;\r\n\tborder-radius: 24px;\r\n\tobject-fit: cover;\r\n}\r\n\r\n.iconWrap {\r\n\tposition: absolute;\r\n\ttop: 12px;\r\n\tleft: 12px;\r\n}\r\n\r\n.textWrap {\r\n\twidth: 160px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CarsItem": `CarsItem_CarsItem__wmqD5`,
	"imgWrap": `CarsItem_imgWrap__j+pU9`,
	"img": `CarsItem_img__ierON`,
	"iconWrap": `CarsItem_iconWrap__ZPOSq`,
	"textWrap": `CarsItem_textWrap__arwEC`
};
export default ___CSS_LOADER_EXPORT___;
