import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "app/providers/StoreProvider"
import { AxiosError } from "axios"
import { $api } from "shared/api/api"

interface AddFavoritedCarProps {
	userId: number;
	carId: number;
}

export const addFavoritedCar = createAsyncThunk<
	void, 
	AddFavoritedCarProps, 
	ThunkConfig<string>
>(
	"cars/addFavoritedCar",
	async (props, thunkAPI) => {
		const {
			rejectWithValue
		} = thunkAPI

		const {
			carId,
			userId
		} = props

		const requestBody: {
			CarId: number,
			UserId: number
		} = {
			CarId: carId,
			UserId: userId
		}

		try {
			const response = await $api.post(`/favourites`, requestBody, {
				headers: {
					"Content-Type": "application/json"
				}
			})

			return response.data
		} catch (e: unknown) {
			const err = e as AxiosError
			const status = err.response?.status
			if (status === 500) {
				return rejectWithValue("Произошла ошибка при попытке добавить автомобиль в избранное. Попробуйте ещё раз...")
			}
		}	
	}
)