import { default as CarouselLib } from 'react-elastic-carousel';
import { CarouselPagination } from "../CarouselPagination/CarouselPagination"
import { CarouselItem } from "../../model/carouselItems"
import cls from "./Carousel.module.scss"
import { classNames } from "shared/lib/classNames/classNames"
import { CSSProperties, memo } from "react";

interface CarouselProps {
	items: CarouselItem[],
	itemsStyle: CSSProperties,
	className?: string;
	autoPlay?: boolean;
	positionPagination?: boolean;
	itemsToShow?: number;
}

export const Carousel = memo((props: CarouselProps) => {
	
	const {
		items,
		itemsStyle,
		className,
		autoPlay,
		positionPagination,
	} = props

	return (
		//@ts-ignore
		<CarouselLib
			className = {classNames(cls.carouselLib, {}, [className])}
			itemsToShow = {1}
			showArrows = {false}
			renderPagination = {({ pages, activePage, onClick }) => 
				<CarouselPagination 
					activePage={activePage}
					pages={pages}
					onClick={onClick}
					className = {positionPagination ? cls.posPagination : ""}
				/>
			}
			enableAutoPlay = {autoPlay}
			autoPlaySpeed = {7000}
		>
			{items.map((item: CarouselItem) => {
				return (
					<img 
						src = {item.url}
						alt = {item.title}
						key = {item.title}
						style = {itemsStyle}
					/>
				)
			})}
		</CarouselLib>
	)
})