export enum ColorType {
	WHITE = "white",
	RED = "red",
	BLUE = "blue",
	BLACK = "black",
	GREY = "grey",
	YELLOW = "yellow",
	GREEN = "green",
	PEARL = "pearl",
	SILVER = "silver"
}

export interface ColorItem {
	value: ColorType,
	content: string,
	colorRgb: string
}