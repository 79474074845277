export enum CarFuelType {
	GASOLINE = "gasoline",
	DIESEL = "diesel",
	GASOLINE_ELECTRIC = "gasoline",
	DIESEL_ELECTRIC = "diesel",
	ELECTRIC = "electric"
}

export interface CalculateForm {
	price?: number,
	issueYear?: number,
	fuelType?: CarFuelType,
	engineStats?: number
}

export interface ResponseForm {
	CarPrice: number, // цена автомобиля по курсу нашей компании +
	AllFees: number, // сборы, налоги, таможня вместе
	CustomsDuty: number, // пошлина +
	CustomsFee: number, //таможенный сбор +
	RecyclingFee: number, //утилизационный сбор +
	Excise?: number, // акциза, может отсутствовать +
	ServicesInKorea: number, //услуги в корее +
	ServicesInRF: number, // услуги в РФ +
}

export interface CalculateSchema {
	error?: string;
	isLoading: boolean,
	form?: CalculateForm,
	responseForm?: ResponseForm,
	validateErrors?: string[],
	totalPrice?: number
}