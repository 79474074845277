import { configureStore, ReducersMapObject } from "@reduxjs/toolkit"
import { StateSchema } from "./types"
import { carsReducer } from "entity/Cars"
import { filterReducer } from "feautures/FilterCars"
import { calculateReducer } from "feautures/CalculateCar"

export function createReduxStore(
	initialState?: StateSchema,
	asyncReducers?: ReducersMapObject<StateSchema>
) {
	const rootReducers: ReducersMapObject<StateSchema> = {
		...asyncReducers,
		cars: carsReducer,
		filter: filterReducer,
		calculate: calculateReducer
	}

	const store = configureStore({
		reducer: rootReducers,
		devTools: true,
		preloadedState: initialState
	})

	return store
}

export type AppDispatch = ReturnType<typeof createReduxStore>["dispatch"]