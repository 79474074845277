import { classNames } from "shared/lib/classNames/classNames"
import cls from "./ColorsListItem.module.scss"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text";
import { ColorItem, ColorType } from "../../model/types/colorsTypes";
import { memo } from "react";

interface ColorsListItemProps {
	className?: string;
	item: ColorItem;
	onChangeColor?: (value: ColorType) => void;
}

export const ColorsListItem = memo((props: ColorsListItemProps) => {

	const {
		className,
		item,
		onChangeColor
	} = props

	return (
		<div 
			className = {classNames(cls.ColorsListItem, {}, [className])}
			onClick = {() => onChangeColor?.(item.value)}
		>
			<div 
				className = {cls.color}
				style = {{backgroundColor: item.colorRgb}}
			>
			</div>
			<Text 
				className = {cls.text}
				weight = {TextWeight.S}
				size = {TextSize.L}
				text = {item.content}
			/>
		</div>
	)
})