// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CarouselPagination_pagination__uewqs {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.CarouselPagination_item__ieij6 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #D9D9D9;
  margin-right: 10px;
  cursor: pointer;
}

.CarouselPagination_active__MKpNA {
  width: 12px;
  height: 12px;
  background-color: white;
  border: 2px solid #D9D9D9;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Carousel/ui/CarouselPagination/CarouselPagination.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,gBAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;AACD","sourcesContent":[".pagination {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tmargin-top: 20px;\r\n}\r\n\r\n.item {\r\n\twidth: 10px;\r\n\theight: 10px;\r\n\tborder-radius: 50%;\r\n\tbackground-color: #D9D9D9;\r\n\tmargin-right: 10px;\r\n\tcursor: pointer;\r\n}\r\n\r\n.active {\r\n\twidth: 12px;\r\n\theight: 12px;\r\n\tbackground-color: white;\r\n\tborder: 2px solid #D9D9D9;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `CarouselPagination_pagination__uewqs`,
	"item": `CarouselPagination_item__ieij6`,
	"active": `CarouselPagination_active__MKpNA`
};
export default ___CSS_LOADER_EXPORT___;
