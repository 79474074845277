import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CalculateSchema, CalculateForm, ResponseForm } from "../types/calculate"
import { calculateCarPrice } from "../services/calculateCarPrice/calculateCarPrice"

const initialState: CalculateSchema = {
	form: {},
	isLoading: false
}

export const calculateSlice = createSlice({
	name: "calculateFormSlice",
	initialState: initialState,
	reducers: {
		updateField: (state, action: PayloadAction<CalculateForm>) => {
			state.form = {
				...state.form,
				...action.payload
			}
		},
		clearForm: (state) => {
			state.form = {}
		},
		clearData: (state) => {
			state.validateErrors = undefined
			state.error = undefined
			state.isLoading = false
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(calculateCarPrice.pending, (state) => {
				state.isLoading = true
				state.error = undefined
				state.validateErrors = undefined;
			})
			.addCase(calculateCarPrice.fulfilled, (state, action: PayloadAction<ResponseForm>) => {
				state.isLoading = false
				state.validateErrors = undefined;
				state.responseForm = action.payload
				state.totalPrice = state.responseForm.CarPrice + state.responseForm.AllFees + state.responseForm.ServicesInKorea + state.responseForm.ServicesInRF
			})
			.addCase(calculateCarPrice.rejected, (state, action) => {
				state.isLoading = true
				if (typeof action.payload === 'string') {
					state.error = action.payload
				} else {
					state.validateErrors = action.payload
				}
				state.isLoading = false
			})
	}
})

export const { actions: calculateActions } = calculateSlice
export const { reducer: calculateReducer } = calculateSlice