import { classNames, Mods } from "shared/lib/classNames/classNames"
import { ChangeEvent, CSSProperties, InputHTMLAttributes, memo, useEffect, useRef, useState } from "react"
import cls from "./Input.module.scss"

export enum InputTheme {
	DEFAULT = "default"
}

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value" | "readOnly">

interface InputProps extends HTMLInputProps {
	className?: string,
	value?: string | number,
	placeholder?: string,
	type?: string,
	onChange?: (value: any) => void,
	readonly?: boolean,
	readonlyForProfile?: boolean,
	autoFocus?: boolean
	theme?: InputTheme,
	style?: CSSProperties
}

export const Input = memo((props: InputProps) => {

	const { 
		className,
		value,
		placeholder,
		autoFocus,
		type,
		onChange,
		readonly,
		theme = InputTheme.DEFAULT,
		style = {}
	} = props

	const [styles, setStyles] = useState<CSSProperties>()
	const ref = useRef<HTMLInputElement>(null)

	useEffect(() => {
		setStyles(style)
	}, [])

	useEffect(() => {
		if (autoFocus) {
			ref.current?.focus()
		}
	}, [autoFocus])

	const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		onChange?.(e.target.value)
	}

	const onBlur = () => {
		setStyles({})
	}

	const mods: Mods = {
		[cls[theme]]: true
	}

	return (
		<input 
			ref = {ref}
			className = {classNames(cls.Input, mods, [className])}
			style = {styles}
			value = {value}
			placeholder = {placeholder || "Текст"}
			onChange = {onChangeHandler}
			readOnly = {readonly}
			type = {type || "text"}
			onBlur = {onBlur}
		></input>
	)
})