// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CarsDetail_icon__STtbm,
.CarsDetail_favorited__iR5RN {
  cursor: pointer;
}

.CarsDetail_content__CHXOo {
  margin-top: 5px;
  border-radius: 42px;
  width: 100%;
  padding: 30px 15px;
}

.CarsDetail_contentWrap__Guq4W {
  padding: 0px 10px;
}

.CarsDetail_characteristicWrap__rxkuE {
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.CarsDetail_characteristic__SYq7- {
  margin-left: 5px;
}

.CarsDetail_contentMain__qLwCr {
  margin-top: 25px;
}

.CarsDetail_title__zlhlD {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CarsDetail_link__HW-yd {
  text-decoration: underline;
  cursor: pointer;
}

.CarsDetail_footer__wbNXu {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/entity/Cars/ui/CarsDetail/CarsDetail.module.scss"],"names":[],"mappings":"AAAA;;EAEC,eAAA;AACD;;AAEA;EACC,eAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;AACD;;AAEA;EACC,iBAAA;AACD;;AAEA;EACC,aAAA;EACA,mBAAA;EACA,gBAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;AACD;;AAEA;EACC,0BAAA;EACA,eAAA;AACD;;AAEA;EACC,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACD","sourcesContent":[".icon,\r\n.favorited {\r\n\tcursor: pointer;\r\n}\r\n\r\n.content {\r\n\tmargin-top: 5px;\r\n\tborder-radius: 42px;\r\n\twidth: 100%;\r\n\tpadding: 30px 15px;\r\n}\r\n\r\n.contentWrap {\r\n\tpadding: 0px 10px;\r\n}\r\n\r\n.characteristicWrap {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tmargin-top: 13px;\r\n}\r\n\r\n.characteristic {\r\n\tmargin-left: 5px;\r\n}\r\n\r\n.contentMain {\r\n\tmargin-top: 25px;\r\n}\r\n\r\n.title {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n}\r\n\r\n.link {\r\n\ttext-decoration: underline;\r\n\tcursor: pointer;\r\n}\r\n\r\n.footer {\r\n\tmargin-top: 30px;\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `CarsDetail_icon__STtbm`,
	"favorited": `CarsDetail_favorited__iR5RN`,
	"content": `CarsDetail_content__CHXOo`,
	"contentWrap": `CarsDetail_contentWrap__Guq4W`,
	"characteristicWrap": `CarsDetail_characteristicWrap__rxkuE`,
	"characteristic": `CarsDetail_characteristic__SYq7-`,
	"contentMain": `CarsDetail_contentMain__qLwCr`,
	"title": `CarsDetail_title__zlhlD`,
	"link": `CarsDetail_link__HW-yd`,
	"footer": `CarsDetail_footer__wbNXu`
};
export default ___CSS_LOADER_EXPORT___;
