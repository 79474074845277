import { ColorItem, ColorType } from "../types/colorsTypes";

export const colorsConfig: Record<ColorType, ColorItem> = {
	[ColorType.RED]: {
		value: ColorType.RED,
		content: "Красный",
		colorRgb: "#FF0000"
	},
	[ColorType.BLUE]: {
		value: ColorType.BLUE,
		content: "Синий",
		colorRgb: "#0057FF"
	},
	[ColorType.BLACK]: {
		value: ColorType.BLACK,
		content: "Черный",
		colorRgb: "#000000"
	},
	[ColorType.GREY]: {
		value: ColorType.GREY,
		content: "Серый",
		colorRgb: "#909090"
	},
	[ColorType.YELLOW]: {
		value: ColorType.YELLOW,
		content: "Желтый",
		colorRgb: "#D7DA26"
	},
	[ColorType.GREEN]: {
		value: ColorType.GREEN,
		content: "Зеленый",
		colorRgb: "#44D113"
	},
	[ColorType.PEARL]: {
		value: ColorType.PEARL,
		content: "Голубой",
		colorRgb: "#568FFF"
	},
	[ColorType.SILVER]: {
		value: ColorType.SILVER,
		content: "Серебристый",
		colorRgb: "#757575"
	},
	[ColorType.WHITE]: {
		value: ColorType.WHITE,
		content: "Белый",
		colorRgb: "#FFFFFF"
	},
}