// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalculateForm_inputWrap__sjAqI {
  margin-top: 20px;
}

.CalculateForm_input__UJ854 {
  margin-top: 15px;
}

.CalculateForm_inputEngineStats__ezzsX {
  margin-top: 15px;
}

.CalculateForm_selectEngineStats__vBx9G {
  margin-left: 30px;
  width: 120px;
}

.CalculateForm_btnWrap__zXHeT {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.CalculateForm_error__EG6p8 {
  color: red;
  margin-top: 15px;
}

.CalculateForm_selectWrap__B9n0C {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.CalculateForm_yearOfBtns__g0ek- {
  display: flex;
  margin-top: 10px;
}

.CalculateForm_yearBtn__ryaV8 {
  font-weight: 300;
  font-size: 16px;
  background: #F6F6F6 !important;
  color: black;
}

.CalculateForm_enableBtn__74twA {
  background: rgb(0, 102, 255) !important;
  color: #ffffff !important;
}

.CalculateForm_fuelWrap__hfp80 {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/feautures/CalculateCar/ui/CalculateForm/CalculateForm.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,iBAAA;EACA,YAAA;AACD;;AAEA;EACC,aAAA;EACA,uBAAA;EACA,gBAAA;AACD;;AAEA;EACC,UAAA;EACA,gBAAA;AACD;;AAEA;EACC,aAAA;EACA,8BAAA;EACA,gBAAA;AACD;;AAEA;EACC,aAAA;EACA,gBAAA;AACD;;AAEA;EACC,gBAAA;EACA,eAAA;EACA,8BAAA;EACA,YAAA;AACD;;AAEA;EACC,uCAAA;EACA,yBAAA;AACD;;AAEA;EACC,gBAAA;AACD","sourcesContent":[".inputWrap {\r\n\tmargin-top: 20px;\r\n}\r\n\r\n.input {\r\n\tmargin-top: 15px;\r\n}\r\n\r\n.inputEngineStats {\r\n\tmargin-top: 15px;\r\n}\r\n\r\n.selectEngineStats {\r\n\tmargin-left: 30px;\r\n\twidth: 120px;\r\n}\r\n\r\n.btnWrap {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\tmargin-top: 20px;\r\n}\r\n\r\n.error {\r\n\tcolor: red;\r\n\tmargin-top: 15px;\r\n}\r\n\r\n.selectWrap {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\tmargin-top: 20px;\r\n}\r\n\r\n.yearOfBtns {\r\n\tdisplay: flex;\r\n\tmargin-top: 10px;\r\n}\r\n\r\n.yearBtn {\r\n\tfont-weight: 300;\r\n\tfont-size: 16px;\r\n\tbackground: #F6F6F6 !important;\r\n\tcolor: black;\r\n}\r\n\r\n.enableBtn {\r\n\tbackground: rgb(0, 102, 255) !important;\r\n\tcolor: #ffffff !important;\r\n}\r\n\r\n.fuelWrap {\r\n\tmargin-top: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": `CalculateForm_inputWrap__sjAqI`,
	"input": `CalculateForm_input__UJ854`,
	"inputEngineStats": `CalculateForm_inputEngineStats__ezzsX`,
	"selectEngineStats": `CalculateForm_selectEngineStats__vBx9G`,
	"btnWrap": `CalculateForm_btnWrap__zXHeT`,
	"error": `CalculateForm_error__EG6p8`,
	"selectWrap": `CalculateForm_selectWrap__B9n0C`,
	"yearOfBtns": `CalculateForm_yearOfBtns__g0ek-`,
	"yearBtn": `CalculateForm_yearBtn__ryaV8`,
	"enableBtn": `CalculateForm_enableBtn__74twA`,
	"fuelWrap": `CalculateForm_fuelWrap__hfp80`
};
export default ___CSS_LOADER_EXPORT___;
