import { MarkItem as MarkItemType } from "../../model/types/markTypes"
import { classNames } from "shared/lib/classNames/classNames"
import cls from "./MarkItem.module.scss"
import { CSSProperties, memo } from "react";
import { Themes, useTheme } from "app/providers/ThemeProvider";

interface MarkItemProps {
	className?: string;
	item: MarkItemType
}

export const MarkItem = memo((props: MarkItemProps) => {

	const {
		item,
		className
	} = props

	const { theme } = useTheme()

	const lightStyle: CSSProperties = {
		backgroundColor: "#F6F6F6",
		borderRadius: "10px"
	}

	const darkStyle: CSSProperties = {
		backgroundColor: "#373737",
		borderRadius: "10px"
	}
	
	return (
		<div 
			className = {classNames(cls.MarkItem, {}, [className])}
			style = {theme === Themes.LIGHT ? lightStyle : darkStyle}
		>
			{item.imageLight 
			? <img 
				src = {theme === Themes.LIGHT ? item.imageLight : item.imageDark} 
				alt = {item.title}
				className = {cls.img}
			/> : <div className = {cls.empty}>?</div>}
		</div>
	)
})