import { classNames } from "shared/lib/classNames/classNames"
import cls from "./CarsDetail.module.scss"
import { carDetailCarouselStyle, Carousel } from "widgets/Carousel";
import { useTelegram } from "shared/lib/hooks/useTelegram";
import { Text, TextSize, TextTheme, TextWeight } from "shared/ui/Text/Text";
import LikeIcon from "shared/assets/icons/LikeIcon";
import IsFavoritedIcon from "shared/assets/icons/IsFavoritedIcon";
import { numberWithSpaces } from "shared/lib/functions/numberWithSpaces";
import { Button, ButtonSize } from "shared/ui/Button/Button";
import { CarCardType, FuelTypeTranslation } from "../../model/types/cars";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCarsFavorited } from "../../model/selectors/getCarsFavorited/getCarsFavorited";
import { useAppDispatch } from "shared/lib/hooks/useAppDispatch";
import { addFavoritedCar } from "../../model/services/addFavoritedCar";
import { removeFavoritedCar } from "../../model/services/removeFavoritedCars";
import { Themes, useTheme } from "app/providers/ThemeProvider";
import { createCarPhotosArray } from "shared/lib/functions/createCarPhotosArray";

interface CarsDetailProps {
	className?: string,
	car?: CarCardType,
	onOpenOrderModal?: () => void
}

export const CarsDetail = memo((props: CarsDetailProps) => {

	const {
		className,
		car,
		onOpenOrderModal
	} = props

	const { theme } = useTheme()
	const { user } = useTelegram()
	const [ isFavorited, setIsFavorited ] = useState<boolean>()
	const favoritedCars = useSelector(getCarsFavorited) 
	const dispatch = useAppDispatch()
	const carPhotos = createCarPhotosArray(car ? car?.PhotosPrefix : "", 10)

	let fuel;
	let engineType;
	let transmission;

	switch(car?.FuelType) {
		case FuelTypeTranslation.DIESEL || FuelTypeTranslation.DIESEL_ELECTRIC: {
			fuel = "Дизель"
			engineType = "см3"
			break;
		}
		case FuelTypeTranslation.GASOLINE || FuelTypeTranslation.GASOLINE_ELECTRIC || FuelTypeTranslation.GASOLINE_LPG: {
			fuel = "Бензин"
			engineType = "см3"
			break;
		}
		case FuelTypeTranslation.ELECTRO || FuelTypeTranslation.LPG_ELECTRIC: {
			fuel = "Электричество"
			engineType = "кВт"
			break;
		}
		case FuelTypeTranslation.HYDROGEN: {
			fuel = "Водород"
			engineType = "л.с."
			break;
		}
		case FuelTypeTranslation.LPG: {
			fuel = "Сжиженный газ"
			engineType = "л.с."
			break;
		}
		default: { 
			fuel = "-" 
			engineType = ""
		}
	}

	switch (car?.Transmission) {
		case 'auto': {
			transmission = "Автоматическая"
			break
		}
		case 'manual': {
			transmission = "Механика"
			break
		}
		default: {
			transmission = "-"
		}
	}

	useEffect(() => {
		const find = favoritedCars?.find(item => item.Id === car?.Id)
		if (find) {
			setIsFavorited(true)
		}
	}, [car])

	const onFollowToEncar = () => {
		window.open(car?.EncarLink, "_blank")
	}

	const onAddFavorited = useCallback(async () => {
		//@ts-ignore
		const res = await dispatch(addFavoritedCar({ carId: car?.Id, userId: user?.id }))
		//@ts-ignore
		if (res.meta.requestStatus === "fulfilled") {
			setIsFavorited(true)
		}
	}, [car])

	const onRemoveFavorited = useCallback(async () => {
		//@ts-ignore
		const res = await dispatch(removeFavoritedCar({ carId: car?.Id, userId: user?.id }))
		//@ts-ignore
		if (res.meta.requestStatus === "fulfilled") {
			setIsFavorited(false)
		}
	}, [car])

	//@ts-ignore
	const carouselItem: CarouselItem[] = carPhotos.map((item) => {
		if (item) {
			console.log(item.url)
			return {  
				title: item.id,
				url: item.url
			}
		}
	}) || []

	return (
		<div className = {classNames(cls.CarsDetail, {}, [className])}>
			<Carousel
				items = {carouselItem}
				itemsStyle = {carDetailCarouselStyle}
				className = {cls.carousel}
				positionPagination
			/>
			<div className = {cls.contentWrap}>
				<div 
					className = {cls.content}
					style = {theme === Themes.LIGHT ? {backgroundColor: "#ffffff"} : {}}
				>
					<div className = {cls.contentHeader}>
						<div className = {cls.title}>
							<Text
								text = {`${car?.Manufacturer} ${car?.Model}`}
								weight = {TextWeight.XXL}
								size = {TextSize.XL}
							/>
							<span 
								onClick = {!isFavorited ? onAddFavorited : onRemoveFavorited}
								className = {cls.favorited}
							>
								{!isFavorited ? <LikeIcon/> : <IsFavoritedIcon/>}
							</span>
						</div>
						<span onClick = {onFollowToEncar}>
							<Text
								text = {"Открыть на сайте Encar"}
								size = {TextSize.XS}
								theme = {TextTheme.GREY}
								className = {cls.link}
							/>
						</span>
					</div>
					<div className = {cls.contentMain}>
						<Text
							text = {"Характеристики"}
							weight = {TextWeight.XL}
						/>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"Год выпуска: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
							/>
							<Text
								text = {String(car?.IssueYear) || "-"}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [])}
							/>
						</div>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"Поколение: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
							/>
							<Text
								text = {car?.Badge || "-"}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [])}
							/>
						</div>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"Пробег: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
							/>
							<Text
								text = {`${car?.Mileage} км` || "-"}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [])}
							/>
						</div>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"Тип топлива: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
								className = {cls.blackText}
							/>
							<Text
								text = {fuel}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [cls.blackText])}
							/>
						</div>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"Трансмиссия: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
								className = {cls.blackText}
							/>
							<Text
								text = {transmission || "-"}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [cls.blackText])}
							/>
						</div>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"VIN: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
								className = {cls.blackText}
							/>
							<Text
								text = {car?.VIN || "-"}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [cls.blackText])}
							/>
						</div>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"Мотор: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
								className = {cls.blackText}
							/>
							<Text
								text = {car?.EngineStats + ` ${engineType}, ${car?.Engine}` || "-"}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [cls.blackText])}
							/>
						</div>
						<div className = {cls.characteristicWrap}>
							<Text
								text = {"Цвет: "}
								size = {TextSize.XS}
								weight = {TextWeight.S}
								className = {cls.blackText}
							/>
							<Text
								text = {car?.Color || "-"}
								size = {TextSize.XS}
								className = {classNames(cls.characteristic, {}, [cls.blackText])}
							/>
						</div>
					</div>
					<div className = {cls.footer}>
						<div className = {cls.priceWrap}>
							<Text
								weight = {TextWeight.S}
								size = {TextSize.XS}
								text = {"Цена"}
								className = {cls.blackText}
							/>
							<Text
								weight = {TextWeight.XL}
								size = {TextSize.XL}
								text = {`${numberWithSpaces(car?.Price || 0)}р`}
								className = {cls.blackText}
							/>
						</div>
						<Button 
							size = {ButtonSize.L}
							onClick = {onOpenOrderModal}
						>
							Заказать
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
})