import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { FilterForm, FilterSchema } from "../types/filter"

const initialState: FilterSchema = {
	form: {}
}

export const filterSlice = createSlice({
	name: "filterFormSlice",
	initialState: initialState,
	reducers: {
		updateField: (state, action: PayloadAction<FilterForm>) => {
			state.form = {
				...state.form,
				...action.payload
			}
		},
		clearForm: (state) => {
			state.form = {}
		},
		clearCopyForm: (state) => {
			state.copyForm = {}
		},
		setCopyForm: (state) => {
			state.copyForm = state.form
		}
	}
})

export const { actions: filterActions } = filterSlice
export const { reducer: filterReducer } = filterSlice