import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { CarCardType, CarsSchema } from "../types/cars"
import { fetchCars } from "../services/fetchCars"
import { addFavoritedCar } from "../services/addFavoritedCar"
import { StateSchema } from "app/providers/StoreProvider"
import { fetchCarById } from "../services/fetchCarById"
import { fecthFavoritedCars } from "../services/fetchFavoritesCars"
import { removeFavoritedCar } from "../services/removeFavoritedCars"
import { fetchCarsPagination } from "../services/fetchCarsPagination"
import { findCarById } from "../services/findCarById"

const carsAdapter = createEntityAdapter({
	selectId: (car: CarCardType) => car.Id,
})

export const getCarsList = carsAdapter.getSelectors<StateSchema>(
	(state: StateSchema) => state.cars || carsAdapter.getInitialState()
)

export const carsSlice = createSlice({
	name: "carsCardSlice",
	initialState: carsAdapter.getInitialState<CarsSchema>({
		isLoading: false,
		from: 0,
		count: 80,
		hasMore: true,
		ids: [],
		entities: {}
	}),
	reducers: {
		setCarsFrom: (state, action: PayloadAction<number>) => {
			state.from = action.payload
		},
		setCarsCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload
		},
		removeCarDetail: (state) => {
			state.carDetail = undefined
		}
	},
	extraReducers: (builder) => {
		builder
			// fetchCars
			.addCase(fetchCars.pending, (state) => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(fetchCars.fulfilled, (state, action: PayloadAction<CarCardType[]>) => {
				state.isLoading = false
				state.error = undefined
				carsAdapter.setAll(state, action.payload)
				state.hasMore = action.payload.length >= 80
			})
			.addCase(fetchCars.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})

			// fetchCarsPagination
			.addCase(fetchCarsPagination.pending, (state) => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(fetchCarsPagination.fulfilled, (state, action: PayloadAction<CarCardType[]>) => {
				state.isLoading = false
				state.error = undefined
				carsAdapter.setAll(state, action.payload)
				state.hasMore = action.payload.length >= 80
			})
			.addCase(fetchCarsPagination.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})

			// fetchCarById
			.addCase(fetchCarById.pending, (state) => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(fetchCarById.fulfilled, (state, action: PayloadAction<CarCardType>) => {
				state.isLoading = false
				state.error = undefined
				state.carDetail = action.payload
			})
			.addCase(fetchCarById.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})

			// fetch favorited cars
			.addCase(fecthFavoritedCars.pending, (state) => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(fecthFavoritedCars.fulfilled, (state, action: PayloadAction<CarCardType[]>) => {
				state.isLoading = false
				state.error = undefined
				state.favoritedCars = action.payload
			})
			.addCase(fecthFavoritedCars.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})

			// add favorited car
			.addCase(addFavoritedCar.pending, (state) => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(addFavoritedCar.fulfilled, (state) => {
				state.isLoading = false
				state.error = undefined
				state.favoritedCars?.push(state.carDetail as CarCardType)
			})
			.addCase(addFavoritedCar.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})

			// remove favorited car
			.addCase(removeFavoritedCar.pending, (state) => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(removeFavoritedCar.fulfilled, (state) => {
				state.isLoading = false
				state.error = undefined
				const tmp = state.favoritedCars?.filter(item => item.Id !== state.carDetail?.Id)
				state.favoritedCars = tmp
			})
			.addCase(removeFavoritedCar.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})

			// findCarById
			.addCase(findCarById.pending, (state) => {
				state.isLoading = true
				state.error = undefined
				state.carDetail = undefined
			})
			.addCase(findCarById.fulfilled, (state, action: PayloadAction<CarCardType>) => {
				state.isLoading = false
				state.error = undefined
				state.carDetail = action.payload
			})
			.addCase(findCarById.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { actions: carsActions } = carsSlice
export const { reducer: carsReducer } = carsSlice