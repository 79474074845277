import { memo, useMemo } from "react"
import { classNames } from "shared/lib/classNames/classNames"
import cls from "./CarsList.module.scss"
import { useSelector } from "react-redux"
import { CarsItem } from "../CarsItem/CarsItem"
import { Text } from "shared/ui/Text/Text"
import { AppLink } from "shared/ui/AppLink/AppLink"
import { getCarsFavorited } from "../../model/selectors/getCarsFavorited/getCarsFavorited"
import { CarCardType } from "../../model/types/cars"

interface CarsListProps {
	cars: CarCardType[];
	className?: string;
}

export const CarsList = memo(({ className, cars }: CarsListProps) => {
	
	const favoritedCars = useSelector(getCarsFavorited)

	const memoCars = useMemo(() => {
		if (JSON.stringify(cars) === JSON.stringify(favoritedCars)) {
			return cars?.map((item) => {
				return (
					<AppLink to = {`/cars/${item.Id}`}>
						<CarsItem 
							car = {item} 
							key = {item.Id}
							isFavorited = {true}
						/>
					</AppLink>
				)
			})
		} else {
			return cars?.map((item, index) => {
			const find = favoritedCars?.find(car => car.Id === item.Id)

				return (
					<AppLink to = {`/cars/${item.Id}`}>
						<CarsItem 
							car = {item} 
							key = {index}
							isFavorited = {find ? true : false}
						/>
					</AppLink>
				)
			})
		}
		
	}, [cars, favoritedCars])

	return (
		<div className = {classNames(cls.CarsList, {}, [className])}>
			<div className = {cls.list}>
				{
					memoCars && memoCars?.length > 0 ? memoCars : <Text text = "Список машин пуст"/>
				}
			</div>
		</div>
	)
})