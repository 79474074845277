import { InputRadioItem } from "shared/ui/InputRadio/InputRadio";

export enum SortingType {
	DEFAULT = "id",
	PRICE_UP = "price",
	PRICE_DOWN = "price desc",
	ISSUE_YEAR_UP = "issue_year",
	ISSUE_YEAR_DOWN = "issue_year desc",
	MILEAGE = "mileage"
}

export const sortItems: InputRadioItem[] = [
	{
		content: "По умолчанию",
		value: SortingType.DEFAULT
	},
	{
		content: "По возрастанию цены",
		value: SortingType.PRICE_UP
	},
	{
		content: "По убыванию цены",
		value: SortingType.PRICE_DOWN
	},
	{
		content: "По году выпуска",
		value: SortingType.ISSUE_YEAR_UP
	},
	{
		content: "По пробегу",
		value: SortingType.MILEAGE
	}
]