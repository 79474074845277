// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_Input__kJlEh {
  border-radius: 10px;
  padding: 4px;
  padding-left: 15px;
  height: 43px;
  outline: none;
  border: none;
  background-color: #F6F6F6;
  font-family: "Roboto", sans-serif;
  color: var(--secondary-text-color);
  font-weight: var(--font-weight-S);
  font-size: var(--font-size-XS);
  font-style: normal;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Input/Input.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,iCAAA;EACA,kCAAA;EACA,iCAAA;EACA,8BAAA;EACA,kBAAA;EACA,WAAA;AACD","sourcesContent":[".Input {\r\n\tborder-radius: 10px;\r\n\tpadding: 4px;\r\n\tpadding-left: 15px;\r\n\theight: 43px;\r\n\toutline: none;\r\n\tborder: none;\r\n\tbackground-color: #F6F6F6;\r\n\tfont-family: \"Roboto\", sans-serif;\r\n\tcolor: var(--secondary-text-color);\r\n\tfont-weight: var(--font-weight-S);\r\n\tfont-size: var(--font-size-XS);\r\n\tfont-style: normal;\r\n\twidth: 100%;\r\n}\r\n\r\n.default {\r\n\t\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": `Input_Input__kJlEh`
};
export default ___CSS_LOADER_EXPORT___;
