import { RouteProps } from "react-router"
import { MainPage } from "pages/MainPage"
import { CarDetailsPage, CarsPage } from "pages/CarsPage"
import { CalculatePage } from "pages/CalculatePage"
import { FavoritedCarsPage } from "pages/FavoritedCarsPage"
import { NotFoundPage } from "pages/NotFoundPage"
import { MarksDetailPage, MarksPage } from "pages/MarksPage"
import { FindCarPage } from "pages/FindCarPage"

export type AppRouteProps = RouteProps & {
	authOnly?: boolean;
}

export enum AppRoutes {
	MAIN = "main",
	CARS = "cars",
	MARKS = "marks",
	MARKS_DETAIL = "marks_detail",
	CAR_DETAIL = "car_detail",
	FIND_CAR = "find_car",
	FAVORITED_CARS = "favorited_cars",
	CALCULATE = "calculate",
	//last
	NOT_FOUND = "not_found"
}

export const routerPath: Record<AppRoutes, string> = {
	[AppRoutes.MAIN]: "/",
	[AppRoutes.CARS]: "/cars",
	[AppRoutes.CAR_DETAIL]: "/cars/", // + :id
	[AppRoutes.MARKS]: "/marks",
	[AppRoutes.MARKS_DETAIL]: "/marks/", // + :mark
	[AppRoutes.FAVORITED_CARS]: "/favorited_cars",
	[AppRoutes.CALCULATE]: "/calculate",
	[AppRoutes.FIND_CAR]: "/find_car",
	//last
	[AppRoutes.NOT_FOUND]: "*"
}

export const routeConfig: Record<AppRoutes, AppRouteProps> = {
	[AppRoutes.MAIN]: {
		path: routerPath.main,
		element: <MainPage/>
	},
	[AppRoutes.CARS]: {
		path: routerPath.cars,
		element: <CarsPage/>,
	},
	[AppRoutes.MARKS]: {
		path: routerPath.marks,
		element: <MarksPage/>
	},
	[AppRoutes.MARKS_DETAIL]: {
		path: routerPath.marks_detail + ":mark",
		element: <MarksDetailPage/>

	},
	[AppRoutes.CAR_DETAIL]: {
		path: routerPath.car_detail + ":id",
		element: <CarDetailsPage/>,
	},
	[AppRoutes.CALCULATE]: {
		path: routerPath.calculate,
		element: <CalculatePage/>,
	}, 
	[AppRoutes.FAVORITED_CARS]: {
		path: routerPath.favorited_cars,
		element: <FavoritedCarsPage/>,
	},
	[AppRoutes.FIND_CAR]: {
		path: routerPath.find_car,
		element: <FindCarPage/>
	}, 
	//last
	[AppRoutes.NOT_FOUND]: {
		path: routerPath.not_found,
		element: <NotFoundPage/>
	}
}