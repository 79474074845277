// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageError_PageError__iHtYh {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageError_contentBtn__PJXAE {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/widgets/PageError/ui/PageError.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;;AAEA;EACC,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD","sourcesContent":[".PageError {\r\n\twidth: 100%;\r\n\theight: 100vh;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n}\r\n\r\n.contentBtn {\r\n\tmargin-top: 5px;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageError": `PageError_PageError__iHtYh`,
	"contentBtn": `PageError_contentBtn__PJXAE`
};
export default ___CSS_LOADER_EXPORT___;
