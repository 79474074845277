import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "app/providers/StoreProvider"
import { AxiosError } from "axios"
import { $api } from "shared/api/api"

interface RemoveFavoritedCarProps {
	userId: number;
	carId: number;
}

export const removeFavoritedCar = createAsyncThunk<
	void, 
	RemoveFavoritedCarProps, 
	ThunkConfig<string>
>(
	"cars/removeFavoritedCar",
	async (props, thunkAPI) => {
		const {
			rejectWithValue
		} = thunkAPI

		const {
			carId,
			userId
		} = props

		const requestBody: {
			CarId: number,
			UserId: number
		} = {
			CarId: carId,
			UserId: userId
		}

		try {
			const response = await $api.delete(`/favourites`, {
				headers: {
					"Content-Type": "application/json"
				},
				data: requestBody
			})

			return response.data
		} catch (e: unknown) {
			const err = e as AxiosError
			const status = err.response?.status
			if (status === 500) {
				return rejectWithValue("Произошла ошибка при попытке удалить автомобиль из избранного. Попробуйте ещё раз...")
			}
		}	
	}
)