import { classNames } from "shared/lib/classNames/classNames"
import cls from "./CarouselPagination.module.scss"
import { memo } from "react";

interface CarouselPaginationProps {
	className?: string;
	pages?: number[];
	activePage?: number;
	onClick?: (indicatorId: string) => void;
}

export const CarouselPagination = memo((props: CarouselPaginationProps) => {
	
	const { 
		className,
		activePage,
		pages,
		onClick: switchItem
	} = props

	return (
		<div className = {classNames(cls.pagination, {}, [className])}>
			{pages?.map((value: number) => {
				return (
					<div 
						key = {value}
						className = {classNames(cls.item, {[cls.active]: value === activePage}, [])}
						onClick = {() => switchItem?.(String(value))}
					></div>
				)
			})}
		</div>
	)
})