import { CSSProperties } from "react";
import MainSlider1 from "shared/assets/images/main-slider-1.png"
import MainSlider2 from "shared/assets/images/main-slider-2.png"

export interface CarouselItem {
	title: string;
	url: string;
}

export const carDetailCarouselStyle: CSSProperties = {
	width: '100%',
	height: '300px',
	objectFit: 'cover'
}

export const mainCarouselStyles: CSSProperties = {
	maxWidth: '100%',
	maxHeight: '100%',
	borderRadius: '15px',
	// objectFit: 'contain'
}

export const mainCarouselItems: CarouselItem[] = [
	{
		title: "car 1",
		url: MainSlider1
	},
	{
		title: "car 2",
		url: MainSlider2
	}
] 