import { classNames } from "shared/lib/classNames/classNames";
import { CarCardType } from "../../model/types/cars";
import LikeIcon from "shared/assets/icons/LikeIcon";
import IsFavoritedIcon from "shared/assets/icons/IsFavoritedIcon";
import cls from "./CarsItem.module.scss"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text";
import { memo } from "react";
import { numberWithSpaces } from "shared/lib/functions/numberWithSpaces";
import { createCarPhotosArray } from "shared/lib/functions/createCarPhotosArray";

interface CarsItemProps {
	className?: string;
	car: CarCardType;
	isFavorited: boolean;
}

export const CarsItem = memo((props: CarsItemProps) => {
	
	const {
		car,
		className,
		isFavorited
	} = props

	const carPhotos = createCarPhotosArray(car.PhotosPrefix, 1)

	return (
		<div className = {classNames(cls.CarsItem, {}, [className])}>
			<div className = {cls.imgWrap}>
				<img 
					src = {carPhotos[0].url} 
					alt = {car.Model}
					className = {cls.img}
				/>
				<span className = {cls.iconWrap}>
					{!isFavorited ? <LikeIcon/> : <IsFavoritedIcon/>}
				</span>
			</div>
			<div className = {cls.textWrap}>
				<Text
					weight = {TextWeight.L}
					size = {TextSize.XS}
					text = {`${car.Manufacturer} ${car.Model}`}
				/>
				<Text
					size = {TextSize.XXS}
					text = {`${car.FuelType}/${car.IssueYear}`}
				/>
				<Text
					size = {TextSize.XXS}
					text = {car.Price === 0 ? "-" : `${numberWithSpaces(car.Price)}р`}
				/>
			</div>
		</div>
	)
})