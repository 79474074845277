import { classNames } from "shared/lib/classNames/classNames"
import { ColorItem, ColorType } from "../../model/types/colorsTypes"
import { colorsConfig } from "../../model/config/colors"
import { ColorsListItem } from "../ColorsListItem/ColorsListItem";
import { memo } from "react";

interface ColorsListProps {
	className?: string;
	onChangeColor?: (value: ColorType) => void;
}

export const ColorsList = memo((props: ColorsListProps) => {

	const {
		className,
		onChangeColor
	} = props

	return (
		<div className = {classNames("", {}, [className])}>
			{Object.values(colorsConfig).map((item: ColorItem) => {
				return (
					<ColorsListItem
						key = {item.value}
						item = {item}
						onChangeColor = {onChangeColor}
					/>
				)
			})}
		</div>
	)
})