import { classNames } from "shared/lib/classNames/classNames"
import { InputRadio } from "shared/ui/InputRadio/InputRadio"
import { SortingType, sortItems } from "../model/config"
import cls from "./SortInputRadio.module.scss"
import { memo, useCallback } from "react"

interface SortInputRadioProps {
	className?: string;
	onChangeSortType?: (value: SortingType) => void;
}

export const SortInputRadio = memo((props: SortInputRadioProps) => {

	const {
		className,
		onChangeSortType
	} = props

	const onChange = useCallback((value: string) => {
		onChangeSortType?.(value as SortingType)
	}, [onChangeSortType])

	return (
		<div className = {classNames(cls.SortInputRadio, {}, [className])}>
			<InputRadio
				name = "sorting"
				items = {sortItems}
				onChange = {onChange}
			/>
		</div>
	)
})