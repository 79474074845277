import { FetchCarsQueryParams } from "../types/cars";

export function createDynamicUrl(params: FetchCarsQueryParams) {
	const {
		count, from, fuel, manuf, mileage_max, 
		mileage_min, year_max, year_min, color,
		sortType, engine_stats_max, engine_stats_min,
		price_max, price_min
	} = params

	let url = `/cars?from=${from}&count=${count}`

	if (fuel) {
		url += `&fuel=${fuel}`
	}

	if (manuf) {
		url += `&manuf=${manuf.toLowerCase()}`
	}

	if (price_min) {
		url += `&price_min=${price_min}`
	}

	if (price_max) {
		url += `&price_max=${price_max}`
	}

	if (mileage_max) {
		url += `&mileage_max=${mileage_max}`
	}

	if (mileage_min) {
		url += `&mileage_min=${mileage_min}`
	}

	if (year_max) {
		url += `&year_max=${year_max}`
	}

	if (year_min) {
		url += `&year_min=${year_min}`
	}

	if (color) {
		url += `&color=${color}`
	}

	if (sortType) {
		url += `&order=${sortType}`
	}

	if (engine_stats_min) {
		url += `&engine_stats_min=${engine_stats_min}`
	}

	if (engine_stats_max) {
		url += `&engine_stats_max=${engine_stats_max}`
	}

	return url
}