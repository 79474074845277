import { classNames, Mods } from "shared/lib/classNames/classNames"
import cls from "./CalculateForm.module.scss"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text"
import { Input } from "shared/ui/Input/Input"
import { useSelector } from "react-redux"
import { useAppDispatch } from "shared/lib/hooks/useAppDispatch"
import { getCalculateFormForm } from "../../model/selectors/getCalculateFormForm/getCalculateFormForm"
import { getCalculateFormError } from "../../model/selectors/getCalculateFormError/getCalculateFormError"
import { getCalculateFormIsLoading } from "../../model/selectors/getCalculateFormIsLoading/getCalculateFormIsLoading"
import { getCalculateFormResponseForm } from "../../model/selectors/getCalculateFormResponseForm/getCalculateFormResponseForm"
import { getCalculateFormValidateErrors } from "../../model/selectors/getCalculateFormValidateErrors/getCalculateFormValidateErrors"
import { Button } from "shared/ui/Button/Button"
import { memo, useCallback, useEffect, useState } from "react"
import { calculateActions } from "../../model/slices/calculateSlice"
import { CarFuelType } from "../../model/types/calculate"
import { calculateCarPrice } from "../../model/services/calculateCarPrice/calculateCarPrice"
import { Loader } from "shared/ui/Loader/Loader"
import { getCalculateFormTotalPrice } from "../../model/selectors/getCalculateCarFormTotalPrice/getCalculateCarTotalPrice"
import { CalculateResult } from "../CalculateResult/CalculateResult"

interface CalculateFormProps {
	className?: string
}

const currentYear = new Date().getFullYear()
const lessThreeYears = currentYear - 2
const lessThreeFiveYears = currentYear - 4
const lessFiveYears = currentYear - 6

export const CalculateForm = memo((props: CalculateFormProps) => {
	
	const {
		className
	} = props

	const dispatch = useAppDispatch()
	const form = useSelector(getCalculateFormForm)
	const error = useSelector(getCalculateFormError)
	const isLoading = useSelector(getCalculateFormIsLoading)
	const responseForm = useSelector(getCalculateFormResponseForm)
	const validateErrors = useSelector(getCalculateFormValidateErrors)
	const totalPrice = useSelector(getCalculateFormTotalPrice)
	const [ isEnableYearThreeBtn, setIsEnableYearThreeBtn ] = useState<boolean>(false)
	const [ isEnableYearThreeFiveBtn, setIsEnableYearThreeFiveBtn ] = useState<boolean>(false)
	const [ isEnableYearFiveBtn, setIsEnableYearFiveBtn ] = useState<boolean>(false)
	const [ isEnableGasolineBtn, setIsEnableGasolineBtn ] = useState<boolean>(false)
	const [ isEnableElectricBtn, setIsEnableElectricBtn ] = useState<boolean>(false)
	const [ engineSizeLable, setEngineSizeLabel ] = useState<string>("")

	useEffect(() => {
		return () => {
			dispatch(calculateActions.clearData())
		}
	}, [])

	const onChangePrice = useCallback((value: number) => {
		dispatch(calculateActions.updateField({ price: Number(value) }))
	}, [])

	const onChangeIssueYear = useCallback((value: string) => {
		dispatch(calculateActions.updateField({ issueYear: Number(value) }))
		if (value === String(lessThreeYears)) {
			setIsEnableYearThreeBtn(true)
			setIsEnableYearThreeFiveBtn(false)
			setIsEnableYearFiveBtn(false)
		} else if (value === String(lessThreeFiveYears)) {
			setIsEnableYearThreeFiveBtn(true)
			setIsEnableYearThreeBtn(false)
			setIsEnableYearFiveBtn(false)
		} else {
			setIsEnableYearFiveBtn(true)
			setIsEnableYearThreeFiveBtn(false)
			setIsEnableYearThreeBtn(false)
		}
	}, [])

	const onChangeFuelType = useCallback((value: CarFuelType) => {
		dispatch(calculateActions.updateField({ fuelType: value }))
		if (value === CarFuelType.GASOLINE) {
			setIsEnableGasolineBtn(true)
			setIsEnableElectricBtn(false)
			setEngineSizeLabel("см3")
		} else if (value === CarFuelType.ELECTRIC) {
			setIsEnableElectricBtn(true)
			setIsEnableGasolineBtn(false)
			setEngineSizeLabel("кВт")
		}
	}, [])

	const onChangeEngineStats = useCallback((value: number) => {
		dispatch(calculateActions.updateField({ engineStats: Number(value) }))
	}, [])

	const onCalculateCarPrice = async () => {
		//@ts-ignore
		await dispatch(calculateCarPrice(form))
	}

	const btnMods: Mods = {
		[cls.enableBtn]: true
	}

	return (
		<div className = {classNames(cls.CalculateForm, {}, [className])}>
			<div className = {cls.inputWrap} title = {"Стоимость в вонах"}>
				<Text
					text = {"Стоимость (воны)"}
					size = {TextSize.L}
					weight = {TextWeight.XL}
				/>
				<Input
					placeholder = "0"
					value = {form?.price}
					className = {cls.input}
					onChange = {onChangePrice}
				/>
			</div>
			<div className = {cls.fuelWrap}>
				<Text
					text = {"Тип топлива"}
					size = {TextSize.L}
					weight = {TextWeight.XL}
				/>
				<div className = {cls.yearOfBtns}>
					<Button 
						className = {classNames(cls.yearBtn, isEnableGasolineBtn ? btnMods : {}, [])}
						onClick = {() => onChangeFuelType(CarFuelType.GASOLINE)}
					>
						Бензин/Дизель/Гибрид
					</Button>
					<Button 
						className = {classNames(cls.yearBtn, isEnableElectricBtn ? btnMods : {}, [])}
						onClick = {() => onChangeFuelType(CarFuelType.ELECTRIC)}
					>
						Электро
					</Button>
				</div>
			</div>
			<div className = {cls.inputWrap}>
				<Text
					text = {`${engineSizeLable === "кВт" ? "Мощность" : "Объем"} ${engineSizeLable}`}
					size = {TextSize.L}
					weight = {TextWeight.XL}
				/>
				<div className = {cls.engineStatsWrap}>
					<Input
						placeholder = "0"
						value = {form?.engineStats}
						className = {cls.inputEngineStats}
						onChange = {onChangeEngineStats}
					/>
				</div>
			</div>
			<div className = {cls.inputWrap}>
				<Text
					text = {"Возраст"}
					size = {TextSize.L}
					weight = {TextWeight.XL}
				/>
				<div className = {cls.yearOfBtns}>
					<Button 
						className = {classNames(cls.yearBtn, isEnableYearThreeBtn ? btnMods : {}, [])}
						onClick = {() => onChangeIssueYear(String(lessThreeYears))}
					>
						Меньше 3-х лет
					</Button>
					<Button 
						className = {classNames(cls.yearBtn, isEnableYearThreeFiveBtn ? btnMods : {}, [])}
						onClick = {() => onChangeIssueYear(String(lessThreeFiveYears))}
					>
						От 3 до 5 лет
					</Button>
					<Button 
						className = {classNames(cls.yearBtn, isEnableYearFiveBtn ? btnMods : {}, [])}
						onClick = {() => onChangeIssueYear(String(lessFiveYears))}
					>
						Старше 5 лет
					</Button>
				</div>
			</div>
			{validateErrors && 
				<Text 
					text = {validateErrors[0]}
					size = {TextSize.S}
					className = {cls.error}
				/>
			}
			<div className = {cls.btnWrap}>
				<Button onClick = {onCalculateCarPrice}>
					Посчитать
				</Button>
			</div>
			{isLoading && <Loader/>}
			<CalculateResult
				responseForm = {responseForm}
				totalPrice = {totalPrice}
			/>
		</div>
	)
})