// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_item__cXEjR {
  width: 400px;
  height: 155px;
  border-radius: 15px;
}

.Carousel_posPagination__X1l6G {
  position: absolute;
  bottom: 20px;
}

.Carousel_carouselLib__ee3Tt {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Carousel/ui/Carousel/Carousel.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;EACA,mBAAA;AACD;;AAEA;EACC,kBAAA;EACA,YAAA;AACD;;AAEA;EACC,kBAAA;AACD","sourcesContent":[".item {\r\n\twidth: 400px;\r\n\theight: 155px;\r\n\tborder-radius: 15px;\r\n}\r\n\r\n.posPagination {\r\n\tposition: absolute;\r\n\tbottom: 20px;\r\n}\r\n\r\n.carouselLib {\r\n\tposition: relative;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Carousel_item__cXEjR`,
	"posPagination": `Carousel_posPagination__X1l6G`,
	"carouselLib": `Carousel_carouselLib__ee3Tt`
};
export default ___CSS_LOADER_EXPORT___;
