import { useTelegram } from "shared/lib/hooks/useTelegram"
import { Suspense, useEffect } from "react"
import { classNames } from "shared/lib/classNames/classNames"
import { AppRouter } from "./providers/RouterProvider"
import "./styles/reset.scss"
import "./styles/index.scss"
import { useNavigate } from "react-router"
import { useTheme } from "./providers/ThemeProvider/lib/useTheme"

function App() {

  const { tg } = useTelegram()
  const navigate = useNavigate()
  const { theme } = useTheme()

  useEffect(() => {
    tg.ready()
    navigate("/")
    //@ts-ignore
  }, [])

  //494284149

  return (
    <div className = {classNames("app", {}, [theme])}>
			<Suspense fallback = "">
					<AppRouter/>
			</Suspense>
		</div>
  );
}

export default App;
