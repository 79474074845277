import { classNames } from "shared/lib/classNames/classNames"
import cls from "./InputRadio.module.scss"
import { Text, TextSize, TextWeight } from "../Text/Text";
import { memo } from "react";

export interface InputRadioItem {
	content: string;
	value: string;
}

interface InputRadioProps {
	className?: string;
	onChange?: (value: string) => void;
	items?: InputRadioItem[];
	name: string;
}

export const InputRadio = memo((props: InputRadioProps) => {

	const {
		className,
		items,
		onChange,
		name
	} = props

	return (
		<div className = {classNames(cls.InputRadio, {}, [className])}>
			{items?.map((item) => {
				return (
					<div 
						className = {classNames(cls.wrap, {}, [])}
						key = {item.value}
						onClick = {() => onChange?.(item.value)}
					>
						<input 
							className = {cls.radio} 
							name = {name} 
							type = "radio" 
							value = {item.value}
						/>
						<Text 
							text = {item.content} 
							weight = {TextWeight.S} 
							size = {TextSize.S}
							className = {cls.radioText}
						/>
					</div>
				)
			})}
		</div>
	)
})