// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarkList_markWrap__MBcpb {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.MarkList_cursor__iY8qS {
  cursor: pointer;
}

.MarkList_text__z8QdM {
  margin-left: 10px;
}

.MarkList_link__35KaZ {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/entity/Marks/ui/MarkList/MarkList.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;AACD;;AAEA;EACC,eAAA;AACD;;AAEA;EACC,iBAAA;AACD;;AAEA;EACC,aAAA;EACA,mBAAA;AACD","sourcesContent":[".markWrap {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tmargin-bottom: 10px;\r\n}\r\n\r\n.cursor {\r\n\tcursor: pointer;\r\n}\r\n\r\n.text {\r\n\tmargin-left: 10px;\r\n}\r\n\r\n.link {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markWrap": `MarkList_markWrap__MBcpb`,
	"cursor": `MarkList_cursor__iY8qS`,
	"text": `MarkList_text__z8QdM`,
	"link": `MarkList_link__35KaZ`
};
export default ___CSS_LOADER_EXPORT___;
